import React from "react";
import { Header, Modal } from "semantic-ui-react";
import { ViewEditDets } from "xAppLib/DataList";
import inventory_model from "../../models/inventory_model";
import { useAsyncUpdate } from "../../xAppLib/Hooks/useAsync";
import { toast } from "react-toastify";

export default function UseInventory({ iid, units_remaining, isOpen, onClose, data, update }) {
	const save = useAsyncUpdate(async (d) => {
		if(d.units <= 0){
			throw new Error('Units used should be more than 0');
		}
		if (d.units > units_remaining) {
			throw new Error('Max. capacity reached: You can use up to the available units');
		}
		else {
			return await inventory_model.use_item({ iid, ...d });
		}
	});

	return <Modal open={isOpen} onClose={onClose} closeIcon>
		<Modal.Header>
			<Header>Use item from inventory</Header>
			<p>There are {units_remaining} units remaining</p>
		</Modal.Header>
		<Modal.Content>
			<ViewEditDets
				newRecord={true}
				itm_data={data}
				det_fields={inventory_model.use_fields}
				onSave_dets={d => {
					save.fn(d)
						.then(() => {
							update?.();
							onClose?.();
							toast.success(`You have used ${d.units} units from inventory`, {
								containerId: 'default',
								position: 'bottom-center',
							});
						})
						.catch(error => {
							toast.error(error.toString(), {
								containerId: 'default',
								position: 'bottom-center',
							});
						});
				}}
				onChange={(name, value) => {
					if (inventory_model.edit_fields.filter(f => f.name === name)?.[0]?.type === 'number')
						return { [name]: Number(value) };
				}}
				loading={save.loading}
				btn_cancel={<span></span>}
			/>
		</Modal.Content>
	</Modal>
}