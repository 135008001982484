import React from "react";
import { Route, Routes, useLocation, useParams, Navigate } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react'
import user_model from 'models/user_model'
import cat_model from 'models/cat_model'

import withData from 'xAppLib/HOC/withData'
import withAccess from 'xAppLib/HOC/withAccess'

import NotFound from "views/content/NotFound";


function withCatRoute(WrappedComponent) {
	return function({...rest}) {
		const params = useParams()
		const location = useLocation()
		if (params['*']) {
			params['*'] = params['*'].replace(/\/$/,'')
			if (location.pathname.startsWith(app.consts.routes.cat_route) || location.pathname.startsWith('/admin'+app.consts.routes.cat_route))
				params.cat_route = params['*']
			else if (location.pathname.startsWith(app.consts.routes.med_route) || location.pathname.startsWith('/admin'+app.consts.routes.med_route)) {
				const parts = params['*'].split('/')
				params.med_code = parts.pop()
				params.cat_route = parts.join('/')
			}
			else if (location.pathname.startsWith('/remed/')) {
				const parts = params['*'].split('/');
				params.sid = parts.pop();
				params.med_code = parts.pop();
				params.cat_route = parts.join('/');
			}
		}

		const props = { match: { params }, ...rest }
		return <WrappedComponent match={{params}} { ...props } />
	}

}

import { cat_nms } from 'models/cat_model';

// Core Pages
// import Cat from 'xAppLib/Cat/Cat'
import CatExp from 'xAppLib/Cat/Cat'
import Med from 'views/med/MedNew'

const CatWaccess = withCatRoute(withAccess(CatExp, CatExp.access))
// const MedWaccess = app.runtime?.login_type=='popup' ? Med : withAccess(Med, Med.access);
const MedWaccess = withCatRoute(withAccess(Med, Med.access));

let CatsWdata_arr = {};
let MedsWdata_arr = {};
cat_nms.map(cat_nm=> CatsWdata_arr[cat_nm] = withData(CatWaccess, _=>cat_model.get_cat_list({cat_nm})) )
cat_nms.map(cat_nm=> MedsWdata_arr[cat_nm] = withData(MedWaccess, _=>cat_model.get_cat_list({cat_nm})) )
const MedsWdataPharm = MedsWdata_arr['pharm'];


import Homepage from 'views/pages/Homepage'
import Login from 'xAppLib/Users/Login'
import About from 'views/pages/About'
import Pricing from "./views/pages/Pricing";
import UserProfile from 'xAppLib/Users/UserProfile'
import Password from 'xAppLib/Users/Password'
import OnePass from "./views/pages/OnePass"
import { withSentryReactRouterV6Routing } from '@sentry/react'
import Refer from "./views/pages/Refer";

// Instant Cosmetics
import ICosmHomepage from 'views/icosm/Homepage'
import ICosmTeam from 'views/icosm/Team'
import ICosmContact from 'views/icosm/Contact'
import ICosmNurse from "./views/icosm/Nurse";
import ICosmGrow from "./views/icosm/Grow";
import ICosmValues from "./views/icosm/Values";
import ICosmFaq from "./views/icosm/IcosmFaq";
import ICosmOversight from "./views/icosm/Oversight";
import ICosmSoftware from "./views/icosm/Software";
import ICosmComplicance from "./views/icosm/Compliance";
import ICosmSupport from "./views/icosm/Support";
import ICosmTraining from "./views/icosm/Training";
import ICosmJoin from "./views/icosm/GetStarted";

const Training = React.lazy(_=>import('views/icosm/Training'))
const CreateTreatmentForm = React.lazy(_=>import('views/icosm/treatment/CreateTreatmentForm'))
const ListTreatmentPlans = React.lazy(_=>import('views/icosm/treatment/ListTreatmentPlans'))
const ViewTreatmentPlan = React.lazy(_=>import('views/icosm/treatment/ViewTreatmentPlan'))
const CosmeticTreatmentPlanDoctorConsult = React.lazy(_=>import('views/icosm/treatment/DoctorConsult'))

// Instant PrEP
import IPrepHomepage from 'views/iprep/Homepage'
const IPrepContent = React.lazy(_=>import('views/iprep/Content'))
const IPrepProgress = React.lazy(_=>import('views/iprep/ApplicationProgress'))

// Instant The Green Doc
import ICannHomepage from 'views/icann/Homepage'
const ICannGetStarted = React.lazy(_=>import('views/icann/GetStartedPage'))
const ICannPatient = React.lazy(_=>import('views/icann/PatientPage'))
const ICannSupport = React.lazy(_=>import('views/icann/SupportPage'))
const ICannAboutUs = React.lazy(_=>import('views/icann/AboutUs'))
const ICannFaq = React.lazy(_=>import('views/icann/FAQ'))


// Project Blaze - Breathe Life Sciences
const BlsHomePage = React.lazy(_=>import('views/icann/cbls/HomePage'))
const BlsAboutUs = React.lazy(_=>import('views/icann/cbls/AboutUs'))
const BlsGetStarted = React.lazy(_=>import('views/icann/cbls/GetStartedPage'))
const BlsForPatients = React.lazy(_=>import('views/icann/cbls/PatientPage'))
const BlsSupport = React.lazy(_=>import('views/icann/cbls/SupportPage'))
const BlsFaq = React.lazy(_=>import('views/icann/cbls/FAQ'))

// Project Blaze - Haiku Health
const MgHomePage = React.lazy(_=>import('views/icann/cmg/HomePage'))
const MgAboutUs = React.lazy(_=>import('views/icann/cmg/AboutUs'))
const MgGetStarted = React.lazy(_=>import('views/icann/cmg/GetStartedPage'))
const MgForPatients = React.lazy(_=>import('views/icann/cmg/PatientPage'))
const MgSupport = React.lazy(_=>import('views/icann/cmg/SupportPage'))
const MgFaq = React.lazy(_=>import('views/icann/cmg/FAQ'))
const MgIndications = React.lazy(_=>import('views/icann/cmg/Indications'))

// Instant Ileg
import ILegHomepage from 'views/ileg/Homepage'
const ILegPatient = React.lazy(_=>import('views/ileg/PatientPage'))
const ILegLawyer = React.lazy(_=>import('views/ileg/LawyerPage'))
const ILegRequest = React.lazy(_=>import('views/ileg/RequestPage'))

// Dynamic bundles
const AdminCatX = React.lazy(_=>import('xAppLib/Cat/AdminCat'))
const AdminCat = withCatRoute(AdminCatX)

const AdminOrgs = React.lazy(_=>import('xAppLib/Orgs/AdminOrgs'))
const AdminOrg = React.lazy(_=>import('xAppLib/Orgs/AdminOrg'))
const OrgSignUp = React.lazy(_=>import('xAppLib/Orgs/OrgSignUp'))
const DocSignUp = React.lazy(_=>import('views/doc/DocSignUp'))

const AdminMedX = (React.lazy(_=>import('views/admin/med/AdminMed')))
const AdminMed = withCatRoute(AdminMedX)
const AdminMeds = React.lazy(_=>import('views/admin/med/AdminMeds'))
const AdminProds = React.lazy(_=>import('views/admin/prods/AdminProds'))
const AdminProd = React.lazy(_=>import('views/admin/prods/AdminProd'))
const AdminDocs = React.lazy(_=>import('views/admin/docs/AdminDocs'))
const AdminDoc = React.lazy(_=>import('views/admin/docs/AdminDoc'))
const AdminDocsRoster = React.lazy(_=>import('views/admin/docs/AdminDocsRoster'))
const AdminDocsSkypeCosts = React.lazy(_=>import('views/admin/docs/AdminDocsSkypeCosts'))
const AdminBatchExport = React.lazy(_=>import('views/admin/export/AdminBatchExport'))
const AdminSiteStatus = React.lazy(_=>import('xAppLib/SiteStatus/AdminSiteStatus'))
const AdminDocsAhpra = React.lazy(_=>import('views/admin/docs/AdminDocsAhpra'))

const EditRawData = React.lazy(_=>import('xAppLib/UIelems/EditRawData'))
const EditJsonModal = React.lazy(_=>import('xAppLib/UIelems/EditJsonModal'))

const AdminDevices = React.lazy(_=>import('views/admin/devices/AdminDevices'))
const AdminUsers = React.lazy(_=>import('xAppLib/Users/AdminUsers'))

const ScriptsList = React.lazy(_=>import('views/scripts/ScriptsList'))
const ViewScript = React.lazy(_=>import('views/scripts/ViewScript'))
const InstCons_WR_gp = React.lazy(_=>import('views/instcons/InstCons_WR_gp'))
const InstCons_WR_exprcons = React.lazy(_=>import('views/instcons/InstCons_WR_exprcons'))

const SeshsList = React.lazy(_=>import('xAppLib/Seshs/SeshsList'))

const TransList = React.lazy(_=>import('views/trans/TransList'))
const Stats = React.lazy(_=>import('views/scripts/Stats'))
const MainStats = React.lazy(_=>import('views/stats/MainStats'))

const MedicareStats = React.lazy(_=>import('views/medicare/Stats'))
const MedicareList = React.lazy(_=>import('views/medicare/MedicareList'))

const Patients = React.lazy(_=>import('views/patients/Patients'))
const Patient = React.lazy(_=>import('views/patients/Patient'))

const Prescs = React.lazy(_=>import('views/admin/prescs/Prescs'))
const Notes = React.lazy(_=>import('views/admin/notes/Notes'))
const Cron = React.lazy(_=>import('views/admin/cron/Cron'))

const AdminFeedback = React.lazy(_=>import('views/admin/feedback/AdminFeedback'))
const AdminFeedbackTemplates = React.lazy(_=>import('views/admin/feedback/AdminFeedbackTemplates'))

const BookingAvailability = React.lazy(_=>import('views/booking/BookingAvailability'))
const AdminBookingAvailability = React.lazy(_=>import('views/booking/AdminBookingAvailability'))
const AdminBookingTimePeriods = React.lazy(_=>import('views/booking/AdminBookingTimePeriods'))
const AdminBookingUsers = React.lazy(_=>import('views/booking/AdminBookingUsers'))
const BookingAppointment = React.lazy(_=>import('views/booking/BookingAppointment'))
const BookingRoster = React.lazy(_=>import('views/booking/BookingRoster'))
const BookingAppointmentList = React.lazy(_=>import('views/booking/BookingAppointmentList'))
const AdminBookingAppointment = React.lazy(_=>import('views/booking/AdminBookingAppointment'))
const AdminAppointmentList = React.lazy(_=>import('views/booking/AdminAppointmentList'))
const AdminBookingRoster = React.lazy(_=>import('views/booking/AdminBookingRoster'))


const OrgAdminRosterUsers = React.lazy(_=>import('cosmetics/booking/view/roster/admin/OrgAdminRosterUsers'))
const OrgAdminRosterUser = React.lazy(_=>import('cosmetics/booking/view/roster/admin/OrgAdminRosterUser'))
const OrgAdminAppointmentList = React.lazy(_=>import('cosmetics/booking/view/appointment/admin/OrgAdminAppointmentList'))

const OrgStaffRoster = React.lazy(_=>import('cosmetics/booking/view/roster/staff/OrgStaffRoster'))
const OrgStaffAppointmentList = React.lazy(_=>import('cosmetics/booking/view/appointment/staff/OrgStaffAppointmentList'))
const OrgStaffBooking = React.lazy(_=>import('cosmetics/booking/view/appointment/staff/OrgStaffBooking'))

const Discounts = React.lazy(_=>import('views/admin/discounts/Discounts'))

const KBAdmin = React.lazy(_=>import('xAppLib/KnowledgeBase/KBAdmin'))
const KB = React.lazy(_=>import('xAppLib/KnowledgeBase/KB'))
const KBDirectory = React.lazy(_=>import('xAppLib/KnowledgeBase/KBDirectory'))
const CosmTraining = React.lazy(_=>import('views/pages/CosmTraining'))
const CosmProducts = React.lazy(_=>import('views/pages/CosmProducts'))

const OnlineUsers = React.lazy(_=>import('xAppLib/Team/OnlineUsers'))
const OnlineDoctors = React.lazy(_=>import('views/Team/OnlineDoctors'))
const ChatHome = React.lazy(_=>import('xAppLib/Chat/ChatHome'))
const ChatChannel = React.lazy(_=>import('xAppLib/Chat/ChatChannel'))

const ManageTreatmentPlan = React.lazy(_=>import('views/treatment_plans/user/ManageTreatmentPlan'))
const MyTreatmentPlans = React.lazy(_=>import('views/treatment_plans/user/MyTreatmentPlans'))
const AdminListTreatmentPlans = React.lazy(_=>import('views/treatment_plans/admin/ListTreatmentPlans'))

const History = React.lazy(_=>import('views/pages/History'))
const HistoryWithData = withData(History,cat_model.get_cat_list)

const ProdsSale = React.lazy(_=>import('views/prods/ProdsSale'))
const ProdsStats = React.lazy(_=>import('views/prods/ProdsStats'))
const Shop = React.lazy(_=>import('views/prods/Shop'))
const ShopCategory = React.lazy(_=>import('views/prods/ShopCategory'))
const ShopCart = React.lazy(_=>import('views/prods/ShopCart'))
const ManualScript = React.lazy(_=>import('views/admin/scripts/ManualScript'))
const Data_page = React.lazy(_=>import('views/pages/Data_page'))

const Feedback = React.lazy(_=>import('views/feedback/Feedback'))

const ExprCons = React.lazy(_=>import('views/doc/DocCons-exprcons'))
const DocConsEscript = React.lazy(_=>import('views/doc/DocCons-escript'))
import ProdsNavig from "./xAppLib/ProdsNavig/ProdsNavig";

const DocCons_pts = React.lazy(_=>import('views/doc/DocCons_pts'))
const MIMS = React.lazy(_=>import('views/doc/MIMS'))
const TG = React.lazy(_=>import('views/doc/TG'))
const ResultsFollowup = React.lazy(_=>import('views/doc/ResultsFollowup'))
const ResultsFollowupAll = React.lazy(_=>import('views/doc/ResultsFollowupAll'))
const InstCons_WR = React.lazy(_=>import('views/instcons/InstCons_WR'))

const InstCons_cosm_doc = React.lazy(_=>import('views/instcons/InstCons_cosm_doc'))

const DocNoti = React.lazy(_=>import('views/doc/DocNoti'))

const DocCons_req = React.lazy(_=>import('views/doc/DocCons_req'))

const MB_prices = React.lazy(_=>import('views/medbuy/MB_prices'))
const GS_DB_sync = React.lazy(_=>import('views/data/GS_DB_sync'))

const Results = React.lazy(_=>import('views/results/Results'))
const DashboardResults = React.lazy(_=>import('views/results/DashboardResults'))
const Audit = React.lazy(_=>import('views/admin/audit/Audit'))
const AuditScripts = React.lazy(_=>import('views/admin/audit/AuditScripts'))
const AuditeScripts = React.lazy(_=>import('views/admin/audit/AuditeScripts'))
const AuditeDSPID = React.lazy(_=>import('views/admin/audit/AuditeDSPID'))
const AuditeOrphanIHI = React.lazy(_=>import('views/admin/audit/AuditOrphanIHI'))
const AuditHIServiceIHI = React.lazy(_=>import('views/admin/audit/AuditHIServiceIHI'))
const AuditHIServiceError = React.lazy(_=>import('views/admin/audit/AuditHIServiceError'))
const AuditHIBatch = React.lazy(_=>import('views/admin/audit/AuditHIBatch'))
const DuplicateUsers = React.lazy(_=>import('views/admin/audit/DuplicateUsers'))
const AuditHIOperatorAlerts = React.lazy(_=>import('views/admin/audit/AuditHIOperatorAlerts'))
const VidCallTroubleshooting = React.lazy(_=>import('views/supp/VidCallTroubleshooting'))
const TestMyVideo = React.lazy(_=>import('views/supp/TestMyVideo'))

const ClinicProfile = React.lazy(_=>import('views/clinic_profile/ClinicProfile'))
const ClinicProfileEditor = React.lazy(_=>import('views/clinic_profile/ClinicProfileEditor'))

const MedCert = React.lazy(_=>import('views/public/medcert'))
const PathReq = React.lazy(_=>import('views/public/pathreq'))
const DocLet = React.lazy(_=>import('views/public/letter'))
const DecRef = React.lazy(_=>import('views/public/refer'))

const WaitTime = React.lazy(_=>import('views/public/waittime/waittime'))

const QRGen = React.lazy(_=>import('views/admin/qrgen/qrgen'))
const StatOverview = React.lazy(_=>import('views/admin/stat/overview'))
const StatWaitingRoom = React.lazy(_=>import('views/admin/stat/waiting_room'))

const ApprAlgo = React.lazy(_=>import('views/admin/diagram/approval_algo'))

const Privacy = React.lazy(_=>import('views/content/Privacy'))
const FullTerms = React.lazy(_=>import('views/content/FullTerms'))
const TreatmentPlanTermsOfService = React.lazy(_=>import('views/content/TreatmentPlanTermsOfService'))
const ReferralTermsAndConditions = React.lazy(_=>import('views/content/ReferralTermsAndConditions'))

const AdminProdsNavig = React.lazy(_=>import('xAppLib/ProdsNavig/AdminProdsNavig'))
const CancelFollowUpPresc = React.lazy(_=>import('views/prescs/CancelFollowUpPresc'))

import CMSPage from 'views/cms/CMSPage'
import LandingPage from 'views/cms/LandingPage';
import Blog from 'views/cms/Blog'
import Alert from "./views/NUI/Alert";
const AuditList = React.lazy(_=>import('cosmetics/audit/views/AuditList'));
const AuditPage = React.lazy(_=>import('cosmetics/audit/views/Audit'));
import InventoryList from "./views/inventory/InventoryList";
import DrugBook from "./views/inventory/DrugBook";
import TermsAndConditions from "./views/content/TermsAndConditions";
import ISCustomerTermsAndConditions from "./views/content/ISCustomerTermsAndConditions";
import ISPlatformTermsAndConditions from "./views/content/ISPlatformTermsAndConditions";

const Editor = React.lazy(_=>import('views/editor/Editor'))
const EditorDashboard = React.lazy(_=>import('views/editor/EditorDashboard'))
const EditorViewer = React.lazy(_=>import('views/editor/EditorViewer'))

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

const DEBUG = false

const AppGuard = ({ target, access, component,  no_data_preload = false, children, ...restOfProps }) => {
	if (target && !(target.includes(app.runtime.app) || target.includes(app.runtime.mode))) return null;
	const params = useParams()

	if (!no_data_preload && !app.state.loaded) return <Loader active />;

	if (access) {
		DEBUG && console.log('checking access', (typeof access == 'string' && access) || 'function');
		const r = (typeof access == 'function' && access({params})) || user_model.limit_access(access);
		DEBUG && console.log('access=>', r);

		if (!r) return null;
	}
	const combined = { match: {  params }, ...restOfProps }

	return children ? React.cloneElement(children, combined) : component ? React.createElement(component, combined) : null
};


export const ProfileRoute = () => <SentryRoutes><Route path="/profile" element={<AppGuard access><UserProfile size='large'/></AppGuard>} /></SentryRoutes>;

const PathoCRedir = () => <Navigate to={`/c/pharm/patho/${useParams()?.patho_id||''}`} replace />
const PathoMRedir = () => <Navigate to={`/m/pharm/patho/${useParams()?.patho_id||''}`} replace />

const BLSTempDisabled = () => <Container>
	<Alert className={"mt-8 text-center"} info>
	<p>We have temporarily paused our consultations.</p>
	<p>We will return to full operation soon.</p>
	</Alert>
</Container>

export default function AppRoutes() {
		const popups = app.app_data.ws_conf.popups
		const host = location.host
		
		const is_pharm = host == "pharmapp.instantscripts.com.au" && ( app.state.loaded && app.dvc.org.type=='pharm')
		const is_cosm = ['cosmapp.instantscripts.com.au', 'instantcosmetics.com.au','icosm.com.au','d.icosm.com.au'].includes(host) && (app.dvc.org.type=='cosm' && user_model.check_access('nurse'))

		const target = app.runtime.mode == 'cmg' && 'cmg' || app.runtime.mode == 'cbls' && 'cbls' || app.runtime.app

		return (
				<SentryRoutes>

						<Route path="/c/patho" element={ <PathoCRedir /> }  />
						<Route path="/c/patho/:patho_id" element={ <PathoCRedir /> }  />
						<Route path="/m/patho" element={ <PathoMRedir /> }  />
						<Route path="/m/patho/:patho_id" element={ <PathoMRedir /> }  />

						{target == 'iscr' && <>
							<Route path="/" element={<Homepage />} />
							<Route path="/index.html" element={<Homepage />} />
							<Route path="/navig" element={<ProdsNavig />} />
							<Route path="/onepass" element={<OnePass />} />
							<Route path="/refer-a-friend" element={<Refer />} />
							{!is_pharm && !is_cosm  && <Route path='/about' element={<About />} />}
							<Route path='/pricing' element={<Pricing />} />
						</>}

						{Object.keys(popups).filter(k=>popups[k].target.includes(target))
						.map(k=> <Route key={k} path={`/${popups[k].pn}`} element={
							<AppGuard no_data_preload target={popups[k].target}>
								{ target == 'cbls' && <BlsHomePage popup={popups[k]} />
								|| target == 'cmg' && <MgHomePage popup={popups[k]} />
								|| <Homepage popup={popups[k]} />}
							</AppGuard>} />
						)}

						{target == 'icosm' && <>
							<Route exact path="/" element={app.dvc.org?.type=='cosm' && user_model.check_access('nurse') && <Homepage/> || <ICosmHomepage/>} />
							{/*<Route exact path="/instantcosmetics-team" element={<ICosmTeam/>} />*/}
							<Route exact path="/nurse" element={<ICosmNurse/>} />
							<Route exact path="/grow" element={<ICosmGrow/>} />
							<Route exact path="/our-values" element={<ICosmValues/>} />
							<Route exact path="/oversight" element={<ICosmNurse/>} />
							<Route exact path="/effective-software" element={<ICosmSoftware/>} />
							<Route exact path="/complicance" element={<ICosmComplicance/>} />
							<Route exact path="/medical-oversight" element={<ICosmOversight/>} />
							<Route exact path="/training-events" element={<ICosmTraining/>} />
							<Route exact path="/support" element={<ICosmSupport/>} />
							<Route exact path="/contact" element={<ICosmContact/>} />
							<Route exact path="/instantcosmetics-faqs" element={<ICosmFaq/>} />
							<Route exact path="/join" element={<ICosmJoin/>} />

							<Route path="/training" group='cosm_train' element={<ProdsSale group='cosm_train' />} />

							<Route exact path="/org/my-clinic-profile" element={<AppGuard access={"mang"}><ClinicProfileEditor /></AppGuard>} />
						</>}

						<Route path="/treatments/create/:mid?" element={<AppGuard><CreateTreatmentForm /></AppGuard>} />
						<Route path="/treatments" element={<AppGuard><ListTreatmentPlans view="nurse" /></AppGuard>} />
						<Route path="/treatment/:sid?" element={<AppGuard><ViewTreatmentPlan /></AppGuard>} />

						{target == 'iprep' && <>
							<Route  path="/" element={<IPrepHomepage />} />
							<Route  path="/prep/:what/*" element={<IPrepContent />} />
							<Route  path="/application-progress" element={<AppGuard><IPrepProgress /></AppGuard>} />
						</>}

					   {target == 'cbls' && <>
							<Route path='/' element={<BlsHomePage/>} />
							<Route path='/aboutus' element={<BlsAboutUs/>} />
							<Route path='/getstarted' element={<BlsGetStarted/>} />
							<Route path='/forpatients' element={<BlsForPatients/>} />
							<Route path='/support' element={<BlsSupport/>} />
							{/* <Route path='/med/DocConsICann-cbls' element={<BLSTempDisabled/>}/>
							<Route path='/med/DocConsICannFU-cbls' element={<BLSTempDisabled/>}/> */}
							{/*<Route path='/faq' element={<BlsFaq/>} />*/}
						</> }

						{target == 'cmg' && <>
							<Route path='/' element={<MgHomePage/>} />
							<Route path='/aboutus' element={<MgAboutUs/>} />
							<Route path='/getstarted' element={<MgGetStarted/>} />
							<Route path='/forpatients' element={<MgForPatients/>} />
							<Route path='/support' element={<MgSupport/>} />
							<Route path='/faqs' element={<MgFaq/>} />
							<Route path='/indications' element={<MgIndications/>} />
						</> }


						{target == 'icann' && <>
							<Route  path='/' element={<ICannHomepage />} />
							<Route  path='/getstarted' element={<ICannGetStarted />} />
							<Route  path='/forpatients' element={<ICannPatient />} />
							<Route  path='/support' element={<ICannSupport />} />
							<Route  path='/aboutus' element={<ICannAboutUs />} />
							<Route  path='/faqs' element={<ICannFaq />} />

						</>}

						{target == 'ileg' && <>
							<Route  path="/" element={<ILegHomepage />} />
							<Route  path="/forpatients" element={<ILegPatient />} />
							<Route  path="/forlawyers" element={<ILegLawyer />} />
							<Route  path="/request-demo" element={<ILegRequest />} />
						</>}





						{app.runtime?.login_type=='popup'
						? (
							<Route path="/login" element={app.runtime.mode=='iscr' && <Homepage />
															|| app.runtime.mode=='icosm' && <ICosmHomepage />
															|| app.runtime.mode=='iprep' && <IPrepHomepage />
															|| app.runtime.mode=='cbls' && <BlsHomePage />
															|| app.runtime.mode=='cmg' && <MgHomePage />
															|| app.runtime.mode=='icann' && <ICannHomepage />
															|| <Homepage /> }>
								<Route path=":show" element={null} />
							</Route>
							)
						:	(
							<Route path="/login" element={<Login routed />}>
								<Route path=":show" element={null} />
							</Route>
							)
						}

						<Route path="/r_p/:sid/:enc" element={<Login show={'r_p'} />} />

						<Route path="/profile" element={<AppGuard access><UserProfile size='large'/></AppGuard>}	/>

						<Route path="/profile-password" element={<AppGuard access><Password /></AppGuard>}  />

						<Route path="/cat" element={<AppGuard><CatsWdata_arr.pharm /></AppGuard>}>
							<Route path="/cat/:cat_route/*" element={null} />
						</Route>

						<Route path='/med/*' element={<AppGuard><MedsWdata_arr.pharm /></AppGuard>} />

						<Route path='/remed/*' element={<AppGuard><MedsWdata_arr.pharm req_type='remed' /></AppGuard>} />

						{/* <Route path="/cat_cosm/:cat_route/*" element={<AppGuard><CatsWdata_arr.cosm alt_cat='_cosm' /></AppGuard>} />
						<Route path='/med_cosm/:cat_route/:med_code' element={<AppGuard><MedsWdata_arr.cosm alt_cat='_cosm' /></AppGuard>} /> */}

						{/* <Route path="/admin/cat/:cat_route/*" element={<AppGuard access="admin_med" component={withCatRoute(AdminCat)} />}/>
						<Route path="/admin/med/:cat_route" element={<AppGuard access="admin_med" component={withCatRoute(AdminMed)} />}>
							<Route path="/admin/med/:cat_route/:med_code" element={null} />
						</Route> */}

						<Route  path={`${app.consts.routes.cat_route}`} element={<AppGuard component={CatsWdata_arr.pharm}/>}	/>
						{cat_nms.map(cat_nm => (
							<Route path={`${app.consts.routes.cat_route}/${cat_nm}/*`} element={<AppGuard alt_cat={cat_nm} component={ CatsWdata_arr[cat_nm] } />} key={`cat${cat_nm}`}>
								{/* <Route path={`${app.consts.routes.cat_route}/${cat_nm}/:cat_route`} element={null} /> */}
								{/* <Route path={`${app.consts.routes.cat_route}/${cat_nm}/*`} element={null} /> */}
							</Route>
						))}

						{cat_nms.map(cat_nm=><Route path={`${app.consts.routes.med_route}/${cat_nm}/*`}
										element={<AppGuard alt_cat={cat_nm}  component={MedsWdata_arr[cat_nm]} />} key={`med${cat_nm}`}	/>)}

						<Route path={`/admin${app.consts.routes.cat_route}`} element={<AppGuard access="admin_med" component={AdminCat} />}>
							<Route path={`/admin${app.consts.routes.cat_route}/:cat_nm`} element={null} />
							<Route path={`/admin${app.consts.routes.cat_route}/:cat_nm/*`} element={null} />
						</Route>

						<Route path={`/admin/meds/`} element={<AppGuard access="admin_med" component={AdminMeds} />}>
							<Route path={`:cat_nm`} element={null} />

						</Route>

						<Route path={`/admin${app.consts.routes.med_route}`} element={<AppGuard access="admin_med" component={AdminMed} />}>
							<Route path={`/admin${app.consts.routes.med_route}/:cat_nm`} element={null} />
							{/* <Route path={`/admin${app.consts.routes.med_route}/:cat_nm/:cat_route`} element={null} /> */}
							<Route path={`/admin${app.consts.routes.med_route}/:cat_nm/*`} element={null} />
						</Route>


						{/* disabled for now
						<Route path='/whitecoat/med/:med_code' element={<AppGuard><MedsWdata_arr.pharm public allow_set_usr /></AppGuard>}/>

						<Route path='/caia/m/:med_code' element={<AppGuard><MedsWdata_arr.pharm script_type='medcons' req_type='maas' public allow_set_usrpartn_oid='MEDU_3o'/></AppGuard>} />
						*/}
``
						<Route path="/admin/docsroster" element={<AppGuard access="cust_supp"><AdminDocsRoster /></AppGuard>}/>
						<Route path="/admin/skypecosts" element={<AppGuard access="docs_stats"><AdminDocsSkypeCosts /></AppGuard>}/>
						<Route path="/admin/doc_ahpra" element={<AppGuard access="cust_supp"><AdminDocsAhpra /></AppGuard>}/>

						<Route path="/admin/batchexport" element={<AppGuard access="cust_supp"><AdminBatchExport /></AppGuard>} />
						
						<Route path="/exprcons/:sid/:enc/" element={<AppGuard access="GP"><ExprCons /></AppGuard>} />
						<Route path="/doccons/:sid/:enc/" element={<AppGuard access="GP"><DocConsEscript /></AppGuard>}>
							<Route path={`:list`} element={null} />
						</Route>
						<Route path="/results_followup" element={<AppGuard access="GP"><ResultsFollowup /></AppGuard>} />
						<Route path="/results_followup_all" element={<AppGuard access="GP"><ResultsFollowupAll /></AppGuard>} />

						<Route path="/instcons_wr/exprcons" element={<AppGuard access="doc"><InstCons_WR_exprcons /></AppGuard>} />
						<Route path="/instcons_wr/gp" element={<AppGuard access="doc_gp"><InstCons_WR_gp /></AppGuard>} />
						<Route path="/instcons_wr/:wrnm" element={<AppGuard access="doc"><InstCons_WR /></AppGuard>} />

						<Route path="/instcons_doc/:sid/:wrkey" element={<AppGuard access="doc"><InstCons_cosm_doc /></AppGuard>} />
						<Route path="/cosmetics/consult/:sid" element={<AppGuard access="doc"><CosmeticTreatmentPlanDoctorConsult /></AppGuard>} />
						<Route path="/cosmetics/treatments" element={<AppGuard access="cust_supp"><ListTreatmentPlans view="admin" /></AppGuard>} />

						<Route path="/doccons_view/:sid/:enc" element={<AppGuard><DocCons_pts /></AppGuard>} />
						<Route path="/docs/mims-online" element={<AppGuard><MIMS /></AppGuard>} />
						<Route path="/docs/tg" element={<AppGuard><TG /></AppGuard>} />

						<Route path="/docnoti" element={<AppGuard access="doc_noti"><DocNoti /></AppGuard>} />

						<Route path="/online" element={<AppGuard access="admin"><OnlineUsers /></AppGuard>} />
						<Route path="/online_doctors" element={<AppGuard access="admin"><OnlineDoctors /></AppGuard>} />

						<Route path="/history" element={<AppGuard access={() => user_model.limit_access('user',null,true, app.history.location.pathname)}><HistoryWithData/></AppGuard>}>
							<Route path="/history/:sid" element={null} />
						</Route>
						<Route exact path="/treatmentPlans" element={<AppGuard access={()=>user_model.limit_access('user',null,true, app.history.location.pathname)} ><MyTreatmentPlans/></AppGuard>} />
						<Route exact path="/treatmentPlan/:tpid" element={<AppGuard access={()=>user_model.limit_access('user',null,true, app.history.location.pathname)} ><ManageTreatmentPlan/></AppGuard>} />
						<Route path="/admin/treatmentPlans" element={<AppGuard access="adm_treatment_plan" ><AdminListTreatmentPlans/></AppGuard>} />

						<Route path="/chat" element={<AppGuard access><ChatHome /></AppGuard>}/>

						<Route path="/chat/:channel" element={<AppGuard access><ChatChannel /></AppGuard>}/>

						<Route path='/noaccess' element={<AppGuard><Container><h1 align='center'>No Access</h1></Container></AppGuard>}  />

						<Route path="/admin/orgs" element={<AppGuard access="cust_supp"><AdminOrgs /></AppGuard>}>
							<Route path="/admin/orgs/:oid" element={null} />
						</Route>
						{/* <Route path="/admin/org/:grp_route/:itm_code" element={<AppGuard access="cust_supp"><AdminOrg /></AppGuard>}/> */}

						<Route path="/admin/prods" element={<AppGuard access="admin"><AdminProds /></AppGuard>}>
							<Route path="/admin/prods/:pid" element={null} />
						</Route>
						<Route path="/admin/prod/:pid" element={<AppGuard access="admin"><AdminProd /></AppGuard>}/>

						<Route path="/admin/docs" element={<AppGuard access="admin"><AdminDocs /></AppGuard>}>
							<Route path="/admin/docs/*" element={null} />
						</Route>
						<Route path="/admin/doc/*" element={<AppGuard access="admin"><AdminDoc /></AppGuard>}/>

						<Route path="/admin/site_status" element={<AppGuard access="admin"><AdminSiteStatus /></AppGuard>}/>

						<Route path="/admin/devices" element={<AppGuard access="admin_devices"><AdminDevices /></AppGuard>} />

						<Route path="/admin/stats/:stats_type" element={<AppGuard access="use_stats"><MainStats /></AppGuard>} />

						<Route path="/medicare/stats" element={<AppGuard access="consult_stats"><MedicareStats /></AppGuard>} />
						<Route path="/medicare/list" element={<AppGuard><MedicareList access="root"/></AppGuard>} />

						<Route path="/admin/users"	load_data='db' element={<AppGuard access="user_admin"><AdminUsers /></AppGuard>} />
						<Route path="/admin/users_auth"	load_data='auth' element={<AppGuard access="user_admin"><AdminUsers /></AppGuard>} />

						<Route path="/scripts/list" element={<AppGuard access="view_scripts"><ScriptsList /></AppGuard>}>
							<Route path="/scripts/list/:show_list" element={null} />
						</Route>
						<Route path="/script/:sid" element={<AppGuard><ViewScript /></AppGuard>} />
						<Route path="/reqScriptAppr/:sid/:appr" element={<AppGuard access="script_appr"><ViewScript /></AppGuard>}/>

						<Route access="admin" path="/seshs/list" element={<AppGuard><SeshsList /></AppGuard>}>
							<Route access="admin" path="/seshs/list/:show_list" element={null} />
						</Route>

						<Route path="/patients" element={<AppGuard access="pts_srch"><Patients /></AppGuard>}>
							<Route path="/patients/:show_list" element={null} />
						</Route>

						<Route path="/patient/dp/:data_hash" element={<AppGuard access="pts_view"><Patient /></AppGuard>} />
						<Route path="/patient/ui/:by_id/:by_ihi/:by_uid" element={<AppGuard access="pts_view"><Patient /></AppGuard>} />
						<Route path="/patient/cancel_followup_presc" element={<CancelFollowUpPresc/>} />

						<Route path="/admin/prescs/:show_list?" element={<AppGuard access="prescs"><Prescs /></AppGuard>} />
						<Route path="/admin/unactioned_followups" element={<AppGuard access="cust_supp"><Prescs unactioned_followups={true}/></AppGuard>} />
						<Route path="/admin/unactioned_followups_letter" element={<AppGuard access="followup_letter"><Prescs unactioned_followups_letter={true}/></AppGuard>} />

						<Route path="/admin/notes" element={<AppGuard access="admin"><Notes /></AppGuard>} />
						<Route path="/admin/cron" element={<AppGuard access="admin"><Cron /></AppGuard>} />

						<Route path="/admin/manage_feedback" element={<AppGuard access="manage_feedback"><AdminFeedback /></AppGuard>} />
						<Route path="/admin/manage_feedbacktemplates" element={<AppGuard access="manage_feedback"><AdminFeedbackTemplates /></AppGuard>} />

						<Route path="/admin/discounts/" element={<AppGuard access="discounts"><Discounts /></AppGuard>} />
						<Route path="/admin/kb/:type?" element={<AppGuard access="kb_admin"><KBAdmin /></AppGuard>} />
						<Route path="/documents/:type" element={<AppGuard access='user'><KBDirectory /></AppGuard>} />
						<Route path="/cosmtraining" element={<AppGuard><CosmTraining /></AppGuard>} />
						<Route path="/cosmconsumables" element={<AppGuard><CosmProducts /></AppGuard>} />

						<Route path="/scripts/stats" element={<AppGuard access="scripts_stats"><Stats /></AppGuard>} />
						<Route path="/patho/stats/:stats_type" element={<AppGuard><MainStats /></AppGuard>} />

						<Route path="/trans/list" element={<AppGuard><TransList /></AppGuard>} />
						<Route path="/inventory" element={<AppGuard access={()=>(user_model.check_access('inventory') || user_model.check_access('inventory_admin'))}><InventoryList /></AppGuard>} />
						<Route path="/drugbook/:pid?/:iid?" element={<AppGuard access={()=>(user_model.check_access('drug-book') || user_model.check_access('inventory_admin'))}><DrugBook /></AppGuard>} />


						<Route path="/products/sale" element={<AppGuard access={ProdsSale.access}><ProdsSale /></AppGuard>} />

						<Route path="/products/shop" element={<AppGuard access={Shop.access}><Shop /></AppGuard>} />
						<Route path="/products/cart" element={<AppGuard access={Shop.access}><ShopCart /></AppGuard>} />
						<Route path="/products/shop/:par_pid" element={<AppGuard access={Shop.access}><ShopCategory /></AppGuard>} />

						<Route path="/products/stats" element={<AppGuard access={Shop.access}><ProdsStats/></AppGuard>} />
						<Route path="/payment" element={<AppGuard><ProdsSale group='pay_us' /></AppGuard>} />

						<Route path="/products/bpm" element={<AppGuard><ProdsSale group='bpm' /></AppGuard>} />

						<Route path="/org-signup" element={<AppGuard no_data_preload><OrgSignUp /></AppGuard>} />
						<Route path="/doc-signup" element={<AppGuard no_data_preload><DocSignUp /></AppGuard>} />

						<Route path="/manual_script" element={<AppGuard access="admin"><ManualScript /></AppGuard>} />

						<Route path="/admin/data_page/:data_type/:data_loc" element={<AppGuard access="admin"><Data_page /></AppGuard>}>
							<Route path="/admin/data_page/:data_type/:data_loc/:data_par" element={null} />
						</Route>

						<Route path="/pharm/mb_prices" element={<AppGuard><MB_prices /></AppGuard>} >
							<Route path="/pharm/mb_prices/:for_oid" element={null} />
						</Route>

						<Route path="/admin/meds_data_sync" element={<AppGuard access="admin"><GS_DB_sync /></AppGuard>}>
							<Route path="/admin/meds_data_sync/:for_oid" element={null} />
						</Route>

						<Route path="/admin/raw_data" element={<AppGuard access="admin"><EditJsonModal /></AppGuard>} />
						<Route path="/admin/raw_data_cat" loc='/meds_cat/' element={<AppGuard access="raw_data"><EditRawData /></AppGuard>} />

						<Route path='/missedcall/:sid/:mobilehash' element={<AppGuard><DocCons_req /></AppGuard>} />
						<Route path='/docconsreq/:sid/:mobilehash' element={<AppGuard><DocCons_req /></AppGuard>} />
						
						<Route path='/admin/results_raw' element={<AppGuard><Results /></AppGuard>} />
						<Route path='/admin/results/dashboard' element={<AppGuard access="view_scripts"><DashboardResults /></AppGuard>} />
						<Route path='/admin/results/dashboard/requests' element={<AppGuard access="view_scripts"><DashboardResults /></AppGuard>} />
						
						<Route path="/admin/audit_home" element={<AppGuard access="admin"><Audit /></AppGuard>}/>
						<Route path="/admin/audit/scripts" element={<AppGuard access="admin"><AuditScripts /></AppGuard>}/>
						<Route path="/admin/audit/escripts" element={<AppGuard access="admin"><AuditeScripts /></AppGuard>}/>
						<Route path="/admin/audit/dspid" element={<AppGuard access="admin"><AuditeDSPID /></AppGuard>}/>
						<Route path="/admin/audit/orhpan_ihi" element={<AppGuard access="admin"><AuditeOrphanIHI /></AppGuard>}/>
						<Route path="/admin/audit/hi_ihi" element={<AppGuard access="admin"><AuditHIServiceIHI /></AppGuard>}/>
						<Route path="/admin/audit/hi_alert" element={<AppGuard access="admin"><AuditHIOperatorAlerts /></AppGuard>}/>
						<Route path="/admin/audit/hi_err" element={<AppGuard access="admin"><AuditHIServiceError /></AppGuard>}/>
						<Route path="/admin/audit/dup_user" element={<AppGuard access="admin"><DuplicateUsers /></AppGuard>}/>
						<Route path="/admin/audit/hi_batch" element={<AppGuard access="admin"><AuditHIBatch /></AppGuard>}/>

						<Route path='/verify/cert' element={<AppGuard><MedCert /></AppGuard>} />
						<Route path='/verify/cert/:code' element={<AppGuard><MedCert /></AppGuard>} />
						<Route path='/verify/pathreq/:code' element={<AppGuard><PathReq /></AppGuard>} />
						<Route path='/verify/letter/:code' element={<AppGuard><DocLet /></AppGuard>} />
						<Route path='/verify/refer/:code' element={<AppGuard><DecRef /></AppGuard>} />

						<Route path='/waittime' element={<AppGuard><WaitTime /></AppGuard>} />

						<Route path='/qrgen' element={<AppGuard><QRGen /></AppGuard>} />
						<Route path='/stat/overview' element={<AppGuard><StatOverview /></AppGuard>} />
						<Route path='/stat/waiting_room' element={<AppGuard><StatWaitingRoom /></AppGuard>} />
						<Route path='/admin/meds_appr_algo' element={<AppGuard component={ApprAlgo} />} />

						{!["cmg", "cbls"].includes(target) && <>
							<Route exact path="/customer-terms-and-conditions" element={<Container><ISCustomerTermsAndConditions/></Container>} />
							<Route exact path="/platform-terms-and-conditions" element={<Container><ISPlatformTermsAndConditions/></Container>} />
						</>}
						<Route path='/privacy' element={<Container><Privacy /></Container>} />
						<Route path='/treatment-plan-terms' element={<Container><TreatmentPlanTermsOfService /></Container>} />
						{target == 'iscr' && <Route path='/refer-a-friend-terms' element={<Container><ReferralTermsAndConditions /></Container>} />}

						{target == 'iscr' && <Route path='/terms-conditions' element={<Container><FullTerms  target={target}/></Container>} />}

						<Route path='/test-my-video' element={<AppGuard access="user"><TestMyVideo /></AppGuard>} />
						<Route path='/vidcall-troubleshooting' element={<AppGuard access={() => (app.acl.is_admin || app.acl.is_nurse || app.acl.is_doc)}><VidCallTroubleshooting /></AppGuard>}>
							<Route path='/vidcall-troubleshooting/:oid' element={null} />
						</Route>

						<Route path="/admin/editor" element={<AppGuard access="admin"><Editor /></AppGuard>} />
						<Route path="/admin/landing_pages" element={<AppGuard access="manage_landing_pages"><EditorDashboard /></AppGuard>} />
						<Route path="/lp/:landing_page" element={<AppGuard><EditorViewer /></AppGuard>} />
						<Route path="/healthguide/:landing_page" element={<AppGuard><EditorViewer /></AppGuard>} />

						<Route path="/feedback/:fbtmpid" element={<Feedback />} />

						<Route path="/booking/availability" element={<AppGuard access="bookable_staff"><BookingAvailability /></AppGuard>} />
						<Route path="/booking/roster" element={<AppGuard access="bookable_staff"><BookingRoster /></AppGuard>} />
						<Route path="/booking/appointments" element={<AppGuard access="bookable_staff"><BookingAppointmentList /></AppGuard>} />

						<Route path="/admin/booking/appointment-preview/:booking_type" element={<AppGuard access="cust_supp"><BookingAppointment /></AppGuard>} />

						<Route path="/admin/booking/availability/:uid" element={<AppGuard access="cust_supp"><AdminBookingAvailability /></AppGuard>} />

						<Route path="/admin/booking/roster" element={<AppGuard access="cust_supp"><AdminBookingTimePeriods /></AppGuard>} />
						<Route path="/admin/booking/roster/:uid" element={<AppGuard access="cust_supp"><AdminBookingRoster /></AppGuard>} />

						<Route path="/admin/booking/roster-users" element={<AppGuard access="cust_supp"><AdminBookingUsers /></AppGuard>} />

						<Route path="/admin/booking/appointments" element={<AppGuard access="cust_supp"><AdminAppointmentList /></AppGuard>} />

						<Route path="/admin/booking/appointment_times/:uid" element={<AppGuard access="cust_supp"><AdminBookingAppointment /></AppGuard>} />
						<Route path="/admin/booking/appointment_times/type/:booking_type" element={<AppGuard access="cust_supp"><AdminBookingAppointment /></AppGuard>} />
						
						<Route path="/admin/org-booking/users" element={<AppGuard access={() => app.acl.is_mang}><OrgAdminRosterUsers /></AppGuard>} />
						<Route path="/admin/org-booking/users/:uid" element={<AppGuard access={() => app.acl.is_mang}><OrgAdminRosterUser /></AppGuard>} />
						<Route path="/admin/org-booking/appointments" element={<AppGuard access={() => app.acl.is_mang}><OrgAdminAppointmentList /></AppGuard>} />

						<Route path="/org-booking/roster" element={<AppGuard access={() => app.acl.is_nurse}><OrgStaffRoster /></AppGuard>} />
						<Route path="/org-booking/appointments" element={<AppGuard access={() => app.acl.is_nurse}><OrgStaffAppointmentList /></AppGuard>} />

						<Route path="/admin/org-booking/new/:oid" element={<AppGuard access={() => (app.acl.is_mang || app.acl.is_nurse)}><OrgStaffBooking /></AppGuard>} />
						<Route path="/admin/org-booking/new/:oid/:ur" element={<AppGuard access={() => (app.acl.is_mang || app.acl.is_nurse)}><OrgStaffBooking /></AppGuard>} />


						<Route path="/cosm/audit/list/:type" element={<AppGuard access="audit_user"><AuditList /></AppGuard>} />
						<Route path="/cosm/audit/list" element={<AppGuard access="audit_reviewer"><AuditList /></AppGuard>} />
						<Route path="/cosm/audit/:audit_num" element={<AppGuard access="audit_cosm"><AuditPage /></AppGuard>} />

						{target == 'iscr' && <>
						{/*PAGES*/}
						<Route exact path="/womens-fertility-blood-test/" element={<CMSPage target='pages' url="/womens-fertility-blood-test/"/>} />
						<Route exact path="/pcos-blood-test/" element={<CMSPage target='pages' url="/pcos-blood-test/"/>} />
						<Route exact path="/cholesterol-level-test/" element={<CMSPage target='pages' url="/cholesterol-level-test/"/>} />
						<Route exact path="/weight-loss/" element={<CMSPage target='pages' url="/weight-loss/"/>} />
						<Route exact path="/medication-delivery/" element={<CMSPage target='pages' url="/medication-delivery/"/>} />
						<Route exact path="/syphilis/" element={<CMSPage target='pages' url="/syphilis/"/>} />
						<Route exact path="/gonorrhea/" element={<CMSPage target='pages' url="/gonorrhea/"/>} />
						<Route exact path="/chlamydia/" element={<CMSPage target='pages' url="/chlamydia/"/>} />
						<Route exact path="/treatment-plans/" element={<CMSPage target='pages' url="/treatment-plans/"/>} />
						<Route exact path="/covid-antivirals-1/" element={<CMSPage target='pages' url="/covid-antivirals-1/"/>} />
						<Route exact path="/online-specialist-referrals/" element={<CMSPage target='pages' url="/online-specialist-referrals/"/>} />
						<Route exact path="/food-intolerance-test/" element={<CMSPage target='pages' url="/food-intolerance-test/"/>} />
						<Route exact path="/mental-health-care-plans/" element={<CMSPage target='pages' url="/mental-health-care-plans/"/>} />
						<Route exact path="/online-scripts/" element={<CMSPage target='pages' url="/online-scripts/"/>} />
						<Route exact path="/telehealth-consultations/" element={<CMSPage target='pages' url="/telehealth-consultations/"/>} />
						<Route exact path="/online-medical-certificates-employers/" element={<CMSPage target='pages' url="/online-medical-certificates-employers/"/>} />
						<Route exact path="/covid-pcr-test/" element={<CMSPage target='pages' url="/covid-pcr-test/"/>} />
						<Route exact path="/erectile-dysfunction-test/" element={<CMSPage target='pages' url="/erectile-dysfunction-test/"/>} />
						<Route exact path="/blood-pressure-monitor/" element={<CMSPage target='pages' url="/blood-pressure-monitor/"/>} />
						<Route exact path="/tiredness-test/" element={<CMSPage target='pages' url="/tiredness-test/"/>} />
						<Route exact path="/health-check-online/" element={<CMSPage target='pages' url="/health-check-online/"/>} />
						<Route exact path="/immune-system-test/" element={<CMSPage target='pages' url="/immune-system-test/"/>} />
						<Route exact path="/pregnancy-planning-test/" element={<CMSPage target='pages' url="/pregnancy-planning-test/"/>} />
						<Route exact path="/childrens-doctor-online/" element={<CMSPage target='pages' url="/childrens-doctor-online/"/>} />
						<Route exact path="/ibs-test-online/" element={<CMSPage target='pages' url="/ibs-test-online/"/>} />
						<Route exact path="/doctor-feedback/" element={<CMSPage target='pages' url="/doctor-feedback/"/>} />
						<Route exact path="/smoking/" element={<CMSPage target='pages' url="/smoking/"/>} />
						<Route exact path="/hayfever/" element={<CMSPage target='pages' url="/hayfever/"/>} />
						<Route exact path="/asthma/" element={<CMSPage target='pages' url="/asthma/"/>} />
						<Route exact path="/contraception/" element={<CMSPage target='pages' url="/contraception/"/>} />
						<Route exact path="/blood-pressure/" element={<CMSPage target='pages' url="/blood-pressure/"/>} />
						<Route exact path="/genital-herpes/" element={<CMSPage target='pages' url="/genital-herpes/"/>} />
						<Route exact path="/cholesterol/" element={<CMSPage target='pages' url="/cholesterol/"/>} />
						<Route exact path="/online-uti-treatment/" element={<CMSPage target='pages' url="/online-uti-treatment/"/>} />
						<Route exact path="/mens-hair-loss/" element={<CMSPage target='pages' url="/mens-hair-loss/"/>} />
						<Route exact path="/careers/" element={<CMSPage target='pages' url="/careers/"/>} />
						<Route exact path="/mental-health-services/" element={<CMSPage target='pages' url="/mental-health-services/"/>} />
						<Route exact path="/pregnancy-test-and-care/" element={<CMSPage target='pages' url="/pregnancy-test-and-care/"/>} />
						<Route exact path="/womens-health-services/" element={<CMSPage target='pages' url="/womens-health-services/"/>} />
						<Route exact path="/online-medical-certificates/" element={<CMSPage target='pages' url="/online-medical-certificates/"/>} />
						<Route exact path="/for-pharmacies/" element={<CMSPage target='pages' url="/for-pharmacies/"/>} />
						<Route exact path="/vegan-blood-test/" element={<CMSPage target='pages' url="/vegan-blood-test/"/>} />
						<Route exact path="/online-pathology-requests/" element={<CMSPage target='pages' url="/online-pathology-requests/"/>} />
						<Route exact path="/sti-test-online/" element={<CMSPage target='pages' url="/sti-test-online/"/>} />
						<Route exact path="/gp-online/" element={<CMSPage target='pages' url="/gp-online/"/>} />
						<Route exact path="/doctor-consultation-jobs/" element={<CMSPage target='pages' url="/doctor-consultation-jobs/"/>} />
						<Route exact path="/mens-health/" element={<CMSPage target='pages' url="/mens-health/"/>} />
						<Route exact path="/unsubscribe/" element={<CMSPage target='pages' url="/unsubscribe/"/>} />
						<Route exact path="/womens-health/" element={<CMSPage target='pages' url="/womens-health/"/>} />
						<Route exact path="/telehealth-b/" element={<CMSPage target='pages' url="/telehealth-b/"/>} />
						<Route exact path="/about-us/" element={<CMSPage target='pages' url="/about-us/"/>} />
						<Route exact path="/faq/" element={<CMSPage target='pages' url="/faq/"/>} />
						<Route exact path="/troubleshooting/" element={<CMSPage target='pages' url="/troubleshooting/"/>} />
						<Route exact path="/pharmacy-registration/" element={<CMSPage target='pages' url="/pharmacy-registration/"/>} />
						{/*PAGES*/}
						{/*LP*/}
						<Route exact path="/uti-symptoms" element={<LandingPage slug="uti-symptoms" title="uti" />} />
                        {/*LP*/}
						{/*Temp for testing*/}
						<Route exact path="/new-medical-certificates/" element={<CMSPage target='pages' url="/new-medical-certificates/"/>} />

						{/* <Route exact path="/blog/" element={<Blog />} />
						<Route path="/blog/page/:page" element={<Blog />} /> */}
						{/*BLOG*/}
						{/*BLOG*/}
						</>}

						<Route path='/admin/navig' element={<AppGuard><AdminProdsNavig /></AppGuard>} />

						<Route path='/clinic/:oid' element={<AppGuard><ClinicProfile /></AppGuard>} />

						<Route path='*' element={<NotFound />}/>
				</SentryRoutes>
		);

	}

						// <Route access path="/conf1" render={props=><TestConf {...props} user="Mr Chrome"/>} 	/>
						// <Route access path="/conf2"  render={props=><TestConf {...props} user="Ms Firefox"/>} />
						// <Route access path="/conf3"  render={props=><TestConf {...props} user="Brave Guy"/>} />
						// <Route access path="/conf/:user*"  element={<AppGuard>< /></AppGuard>TestConf}  />
						// <Route access="admin"  path="/__admin/users" element={<AppGuard>< /></AppGuard>AdminUsers} />
						// <Route
						// 		path='/hivprevent'
						// 		// access={Med.access}
	 					// 		render={ p => <MedsWdataPharm {...p}
	 					// 					script_type='medbuy'
	 					// 					req_type='hivprevent'
	 					// 					public
	 					// 					// free_consult
	 					// 					medbuy_only
	 					// 					med_allow_excl
	 					// 					med_code='-LkX1uMs9Z8K_flKmk1S'
	 					// 					// med_code='-Lp65oy7wLVBFs0YgkWf'
	 					// 				 /> }  />

						// TOFIX: direct med seems to be broken.
						// <Route access={Med.access} path='/medbuytest'
						// 		render={ p => <Med {...p}
						// 					script_type='medbuy'
						// 					req_type='hivprevent'
						// 					public
						// 					free_consult
						// 					medbuy_only
						// 					med_code='-Luh799IudIPilgFyXwH'
						// 				/> }  />
						// <Route path="/test" element={<AppGuard>< /></AppGuard>Test} />
