import org_model from 'models/org_model';
import user_model from 'models/user_model';
import prods_model from 'models/prods_model';
import currency from 'currency.js';
import firebase_database from '../../xAppLib/providers/firebase_database';
import discount_engine from '../../models/discount_engine';

const SPECIAL_OFFERS_PID = 'dstBqZs';
export const DEFAULT_DELIVERY_PRICE = 15;
export const SPEC_PRICES = org_model.org_conf_itm(app.user.org, 'prods_sale__med_cosm__spec_prices') || {};
export const PRICE_COEFF = org_model.org_conf_itm(app.user.org, 'prods_sale__med_cosm__price_coeff') || 1;

export const CART_LOC = () =>`cosm_carts/${app.user?.prof?.uid}`;
export const SHOP_ACCESS = () => user_model.limit_access('buy_prods', 'cosm_prod');
export const CAN_BUY = () => user_model.check_access('buy_prods', 'cosm_prod');
export const IS_PRODUCT = (prod) => prod.active && prod.price && prod.supp_oid;
export const COSM_PRODUCTS = (prods) => prods.filter(IS_PRODUCT);
export const COSM_CAT_PRODS = (prods, par_pid) => prods.filter?.((prod) => IS_PRODUCT(prod) && prod.par_pid === par_pid);
export const COSM_CATEGORY = (prods, par_pid) => prods.find?.((prod) => !IS_PRODUCT(prod) && prod.pid === par_pid);
export const COSM_CATEGORIES = (prods) => prods.filter?.((prod) => !IS_PRODUCT(prod) && ![prod.pid, prod.par_pid].includes(SPECIAL_OFFERS_PID));
export const COSM_SPECIAL_OFFERS = (prods) => prods.filter?.((prod) => prod.active && prod.par_pid === SPECIAL_OFFERS_PID);
export const CART_PRODS = (prods, cart) => prods.filter?.((prod) => IS_PRODUCT(prod) && cart?.qty?.[prod.pid]);
export const COSM_TAGS = (prods) => prods.reduce((acc, prod) => {
  prod.conf?.tags?.forEach?.((tag) => acc[tag.toUpperCase()] = [...(acc[tag.toUpperCase()] || []), prod]);
  return acc;
}, {});

export function find_closest_volume_price(volume_price, qty, price) {
	if (!volume_price) return price;
	return Object.entries(volume_price)
		.sort(([a], [b]) => a - b)
		.reduce((acc, [key, val]) => {
			key <= qty && (acc = val);
			return acc;
		}, price);
}

export function calc_price(pid, qty, price, volume_price) {
	const discounted_price = Number(find_closest_volume_price(volume_price, qty, price));
	const item_price = SPEC_PRICES[pid] ? currency(Math.min(discounted_price, SPEC_PRICES[pid])) : currency(discounted_price).multiply(PRICE_COEFF);
	const total = item_price.multiply(qty || 0);
	return { price: item_price, total };
}

function total_per_group(prods, group_pid, cart) {
	const groupProds = prods.reduce((acc, { par_pid, pid, price, conf }) => {
		const { volume_price } = conf ?? {};
		if (par_pid === group_pid) acc[pid] = { price, volume_price };
		return acc;
	}, {});
	const pricePerGroup = Object.entries(cart?.qty ?? {})
		.filter(([pid]) => groupProds[pid])
		.reduce((acc, [pid, q]) => calc_price(pid, q, groupProds[pid].price, groupProds[pid].volume_price).total.add?.(acc), 0)?.value;

	return pricePerGroup;
}

function calc_delivery(cart) {
	const { cosm_prods = [] } = app.app_data.ws_data ?? {};	

	const cat_data = COSM_CATEGORIES(cosm_prods);

	const cart_prods = cosm_prods.filter((p) => cart?.qty?.[p.pid]);
	const oneProdPerGroup = Object.values(Object.fromEntries(cart_prods.reduce((acc, p) => (!p.conf?.has_free_delivery ? [...acc, [p.par_pid, p]] : acc), [])));

	return oneProdPerGroup.reduce((acc, p) => {
		const { has_free_delivery, free_delivery_from, delivery_price } = cat_data.find(({ pid }) => pid === p.par_pid)?.conf ?? {};
		if (has_free_delivery || (free_delivery_from && total_per_group(cart_prods, p.par_pid, cart) >= free_delivery_from)) return acc;
		return acc.add(delivery_price || DEFAULT_DELIVERY_PRICE);
	}, currency(0));
}

export function get_total(cart_prods, cart, discount) {
	const { cosm_prods = [] } = app.app_data.ws_data ?? {};

	const prods = cart_prods ?? CART_PRODS(cosm_prods, cart);

	let qty = 0;
	const total = prods.reduce((acc, { pid, price, conf }) => {
		const { volume_price } = conf ?? {};
		const q = cart?.qty?.[pid] || 0;
		qty += q;

		const { total } = calc_price(pid, q, price, volume_price);
		return acc.add(total);
	}, currency(0));

	const deliveryFee = calc_delivery(cart);
	const discountTotal = (() => {
		try {
			return discount ? discount_engine.apply_cosm_discount(total, discount, prods, deliveryFee) : 0;
		} catch (err) {
			return 0;
		}
	})();

	const subtotal = total.add(deliveryFee);
	const totalWithoutGst = subtotal.add(discountTotal);
	const gst = totalWithoutGst.multiply(0.1);
	const totalWithGst = totalWithoutGst.add(gst);

	return { total, deliveryFee, gst, totalWithoutGst, totalWithGst, qty, subtotal, discountTotal };
}

export async function fetch_prods() {
	if (app.app_data.ws_data?.cosm_prods) return app.app_data.ws_data.cosm_prods;

	const prods = (await prods_model.get_record(null, null, { act: 'all_prods', group: 'cosm_prod' })).path ?? [];

	app.app_data.ws_data = {
		...app.app_data.ws_data,
		cosm_prods: prods
	};

	return prods;
}

export function failed_min_requirements(cart) {
	const prod_data = app.app_data.ws_data?.cosm_prods ?? [];
	const cat_data = COSM_CATEGORIES(prod_data);
	const { qty } = cart ?? {}; 

	for (const group of cat_data.filter((c) => c.conf)) {
		const { min_grp_price, min = 0, exclude = [] } = group.conf;

		const prods = prod_data.filter(p=> p.par_pid == group.pid && !exclude.includes(p.pid));

		const total = prods.reduce((total,p)=> total + (qty?.[p.pid] || 0), 0);
		
		const failedSectionLimit = min_grp_price && (() => {
			const totalPerGroup = total_per_group(prods, group.pid, cart);
			return !!totalPerGroup && totalPerGroup <= Number(min_grp_price);
		})();

		if (total && total < min || failedSectionLimit) {
			return `Your order does not match the minimum requirements in ${group.name}.`;
		} 
	}
}

export function save_cart(recordExist, { qty, comments } = {}) {
	const action = recordExist ? 'update_record' : 'set_record';
	firebase_database[action](CART_LOC(), { ...(qty && { qty }), ...(comments && { comments }) }, { add_hist: true });
}

export function delete_cart() {
	delete app.localStorage.cosmDis;
	firebase_database.delete_record(CART_LOC());
}