import React from 'react';
import { Helmet } from "react-helmet"
import IcosmHeader from './components/IcosmHeader';
import OrgPatientAppointments from '../../cosmetics/booking/view/appointment/patient/OrgPatientAppointments';
import { Container, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import IcosmIconList from './components/IcosmIconList';
import IcosmPartners from './components/IcosmPartners';
import IcosmSlider from './components/IcosmSlider';

const img_path = "https://storage.googleapis.com/icosm/"

const business_supp = [
	"Business Support", "Complications Management", "Product Ordering", "Compliance Support", "Clinical Training", "Events & Community", "Emergency Support", "Software"
]

const growth = [
	{
		icon:  "icon-phone.png",
		title: "Script with ease",
		desc: "Enjoy swift and precise consultations with our Doctors"
	},
	{
		icon: "icon-cart.png",
		title: "Shop with confidence",
		desc: "Purchase stock, consumables and skincare all in one place"
	},
	{
		icon: "icon-folder.png",
		title: "Ensure compliancy",
		desc: "Rest assured knowing that you're compliant in managing your patients"
	}
]
const training = [
	{
		icon: "syringe.png",
		title: "Excel in Aesthetics",
		desc: "Looking to further develop your skills, find the course that right for you"
		
	},
	{
		icon: "climbing_stairs.png",
		title: "Educational Pathways",
		desc: "We've collaborated with industry-leading brands to empower your success"
		
	},
	{
		icon: "person_on_laptop.png",
		title: "Complimentary Training",
		desc: "Take advantage of our FREE webinars, clinical workshops, and events focusing on both treatments and potential complications."
	}
]
const support = [
	{
		icon: "icon-person-phone.png",
		title: "Cosmetic Support",
		desc: "Our support team is just a click away, ensuring a smooth and seamless user experience every day."
	},
	{
		icon: "icon-tablet.png",
		title: "The Huddle",
		desc: "Our FREE to use Huddle platform is your go to for training, events, supplier details, industry news and community"
	},
	{
		icon: "icon-group.png",
		title: "Community",
		desc: "Join a community dedicated to enhancing education, achieving clinical excellence, and offering mutual support"
	}
]

const Homepage = () => {

	return <div className="home">

		<Helmet titleTemplate={null}>
			<title>InstantCosmetics - Welcome to InstantCosmetics</title>
		</Helmet>

		<OrgPatientAppointments />

        <IcosmHeader 
			title="Grow with InstantCosmetics" 
			sub="Beautifully Simple"
			desc="Expand your aesthetic business, broaden your clinical expertise, effortlessly create scripts, acquire top-quality products, receive exceptional training and support, and join an unparalleled community." 
			link="/nurse" 
			link_text="Learn More"
			cls="cta-tertiary"/>

		<IcosmPartners/>

		<div className="bg-icosm-primary pt-0 lg:pt-[124px]">
			<div className="flex flex-col lg:flex-row justify-center md:gap-[67px]">
				<img className="w-full md:w-[442px] mx-auto lg:mx-0 order-2 lg:order-1" src={`https://storage.googleapis.com/icosm/2/Rectangle242.png`} />
				<div className="list text-icosm-secondary w-full lg:w-[650px] p-4 lg:p-0 order-1 lg:order-2">
					<h2 className="mt-0 mb-8">Ways we can Support<br className="lg"/> your Business Success</h2>
					
					<ul className="min-h-[150px] flex flex-col lg:flex-row lg:flex-wrap text-left w-fit mx-auto">
						{business_supp.map((el, i) => <li key={i} className="text-icosm-secondary md:text-icosm_xl lg:w-1/2 list-arrow">{el}</li> )}
					</ul>
				</div>
			</div>
		</div>

		<div className="bg-white py-0 md:pt-[122px] md:pb-[128px]">
			<Container>
				<div className="flex justify-center flex-col md:flex-row gap-9 md:gap-[84px]">
					<div className="text-icosm-primary md:w-[469px]">
						<h3 className="mt-0">Business Growth</h3>
						<p className="text-icosm_md md:text-icosm_base">Elevate your business to new heights with our aesthetics-focused systems. Crafted to provide you with all the necessary tools for achieving success in your enterprise.</p>
						<Button as={Link} size="small" type='button' icon className='cta cta-tertiary' to="/grow">Learn More</Button>
						<IcosmIconList list={growth} dark/>
					</div>
					<div className="min-h-[312px] md:min-h-[497px] w-full md:w-1/2 pt-full bg-cover" style={{backgroundImage: `url(https://storage.googleapis.com/icosm/2/Rectangle430.jpg)`}}></div>
				</div>
			</Container>
		</div>

		<div className="flex relative flex-col md:flex-row bg-icosm-quaternary bg-no-repeat">
			<div className="order-2 md:order-1 bg-cover md:bg-right md:w-1/2 block h-[540px] md:h-auto" style={{backgroundImage: `url(https://storage.googleapis.com/icosm/2/training-programs.jpg)`}}></div>
			<div className="flex flex-col order-1 md:order-2 items-start w-full md:w-1/2 max-w-[690px] text-icosm-primary px-4 lg:px-[105px] py-9 md:py-[76px] h-fit">
				<h3 className="mt-0">Training Programs<br/> for all Stages</h3>
				<p className="text-icosm_md md:text-icosm_base">Further develop your skills with our advanced training course, or if you are new to the industry, we have educational pathways that will lead you to success.</p>
				<Button as={Link} size="small" type='button' icon className='cta cta-tertiary' to="/training-events">Learn More</Button>
				<IcosmIconList list={training}/>
			</div>
		</div>

		<div className="bg-white py-0 lg:pt-[122px] lg:pb-[128px]">
			<Container>
				<div className="flex justify-center flex-col lg:flex-row gap-9 md:gap-[84px]">
					<div className="text-icosm-primary lg:w-[469px]">
						<h3 className="mt-0">Community & Support</h3>
						<p className="text-icosm_md md:text-icosm_base">We're here for you when you need it the most. Whether it's assistance with orders, product inquiries, event specifics, or training needs, the Instant Cosmetics support team has got you covered.</p>
						<Button as={Link} size="small" type='button' icon className='cta cta-tertiary ' to="/support">Learn More</Button>
						<IcosmIconList list={support} dark/>
					</div>
					<div className="min-h-[312px] md:min-h-[497px] w-full lg:w-1/2 pt-full bg-cover bg-is-gray" style={{backgroundImage: `url(https://storage.googleapis.com/icosm/2/community-support.jpg)`}}></div>
				</div>
			</Container>
		</div>

		<div className="flex justify-center flex-col md:flex-row relative bg-icosm-quaternary">
			<IcosmSlider />
		</div>
	</div>
}

export default Homepage;
