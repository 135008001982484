import React, { useCallback, useState, useEffect } from "react";
import script_model from "models/script_model";
import user_model from "models/user_model";
import { Button, Tab, TabPane } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import UniFormMed from "views/med/UniFormMed";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import Photos from "xAppLib/Photos/Photos";
import Alert from "views/NUI/Alert";
import BookingFields from "views/med/Sections/Forms/BookingFields";
import { useDebouncedValue, useExperiment } from "../../../../xAppLib/Hooks";

function CheckBmi({ fields, med_data, ...args }) {
	const height = args.values.cons_bmi_height / 100;
	const weight = +args.values.cons_bmi_weight;

	const weight_low = weight && med_data.xtra.min_weight && weight < med_data.xtra.min_weight;
	const weight_low_strict = weight_low && med_data.xtra.min_weight_strict;
	const bmi = height && weight && (weight / (height * height)).toFixed(1) || null;
	const debouncedBmi = useDebouncedValue(bmi, 600);
	const bmi_low = bmi && med_data.xtra.min_bmi && bmi < med_data.xtra.min_bmi;
	const bmi_low_strict = bmi_low && !!med_data.xtra.min_bmi_strict;
	const bmi_high = bmi && med_data.xtra.max_bmi && bmi > med_data.xtra.max_bmi;
	const bmi_high_strict = bmi_high && !!med_data.xtra.max_bmi_strict;

	const bmi_appropriate = !(bmi_low || bmi_high);
	const weight_appropriate = !weight_low;
	const out_of_range = !(bmi_appropriate && weight_appropriate);
	const out_of_range_strict = (bmi_low_strict || bmi_high_strict || weight_low_strict) && out_of_range;

	useEffect(() => {
		// if it's null we haven't been able to calculate BMI, so don't reject (the input fields should fail validation)
		fields.cons_bmi_out_of_range.valid_not_required = !out_of_range;
		args.uf_this.handleInputChange({target: {name: 'cons_bmi_out_of_range', value: null}});

		fields.cons_bmi_out_of_range.label = out_of_range_strict
			? fields.cons_bmi_out_of_range._strict
			: fields.cons_bmi_out_of_range._loose;

	}, [out_of_range, out_of_range_strict]);

	const level = out_of_range_strict ? 'error' : out_of_range ? 'warning' : 'success';
	const invalid = args.valids?.cons_bmi_out_of_range === false;

	return <>
        <UniFieldSUI fl={fields.cons_bmi_height} {...args} />
        <UniFieldSUI fl={fields.cons_bmi_weight} {...args} />
        {bmi && debouncedBmi > 0 && (
            <Alert header={`BMI: ${debouncedBmi}`} {...{[level]: true}}>
                {out_of_range && (<>
                    <p>
                        {!bmi_appropriate && <>Your BMI is {bmi_low ? 'lower' : 'higher'} than recommended for this treatment.<br/></>}
                        {!weight_appropriate && <>Your weight is lower than recommended for this treatment.<br/></>}
                    </p>
                    {out_of_range_strict ? (
                        <Button
                            size="big"
                            fluid
                            color={invalid ? "red" : "green"}
                            basic={invalid}
                            as={Link}
                            to="/med/DocConsCov"
                            data-testid="button-doctors-consult"
                            content="Click here to request a Telehealth Consultation with one of our doctors."
                        />
                    ) : (
                        <>
                            <p>You may continue, however it is at the doctor's discretion as to whether any treatment is suitable for you.</p>
                            <UniFieldSUI fl={fields.cons_bmi_out_of_range} {...args} show_label={false}/>
                        </>
                    )}
                </>)}
            </Alert>
        )}
    </>;
}



const DocCons = (props) => {
	const { med_data, script_type, req_type, Section, enabled, visible, has_bmi_requirement } = props
	const [ fields, setFields] = useState([]);
	const hide_desc = ['docrefr', 'pathoreq', 'medcons','medbuy', 'medclick', 'qualcons', 'qualclick', 'qualbuy', 'exprbuy', 'exprcons', 'exprclick'].includes(script_type) && req_type != "medcert"
	const hide_section = ['med-cert', 'med-cert-school', 'med-cert-uni'].includes(med_data.key);
	const location = useLocation()

	useEffect(() => {
		const fields = [...script_model.COMM_DCNS_FIELDS].map(({...field}) => {
			if (/^cons_bmi_/.test(field.name)) {
				field.valid_not_required = !has_bmi_requirement;
			}
			if (app.settings.icann && field.name === "cons_phone") field.label = "Please enter your best contact number that our doctors will reach you on."
			if (app.settings.icann && field.name === "cons_desc") field.label = "Why are you seeking this treatment today?"
			if (req_type === "medcert" && field.name === "cons_desc") field.label = "What are your symptoms?"
			if ((['medcons','exprcons','qualcons', 'docrefr', 'pathoreq'].includes(script_type) || req_type == 'cosm') && field.name === "cons_phone") field.valid_not_required = true
			if (hide_desc && field.name === 'cons_desc') field.valid_not_required = true
			if (req_type == 'cosm' && field.name === 'cons_desc') field.valid_not_required = false
			if (field.name === 'dcs_cert_dates' && req_type === 'dcs_cert') field.valid_not_required = false;
			if (field.name === 'dcs_refr_spec' && req_type === 'dcs_refr') field.valid_not_required = false;
			if (field.name === 'dcs_patho_dets' && req_type === 'dcs_patho') field.valid_not_required = false;
			if (req_type === 'dcs_scr' && ['dcs_med_desc'].includes(field.name)) field.valid_not_required = false;
			return field
		})
		setFields(fields);
	},[])

	return !hide_section && (
		<UniFormMed
			{...props}
			section="doccons"
			fields={fields}
			>
			{(values, valids, uf_this, fields) => {
				const args = {values, valids, uf_this}

				if (!(enabled && visible)) {
					return null;
				}
				const headerType = script_type === 'pathoreq' ? 'Pathology' : !['docrefr', 'medcons', 'exprcons', 'qualcons', 'medbuy', 'medclick', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick'].includes(script_type) ? 'Consultation' : '';
				const header = headerType ? `${headerType} request details` : 'Request details';

				return <Section>
						
						<Section.Header>
							{header}
						</Section.Header>

						{ app.settings.ileg &&
							<Alert
								content={"If you require multiple specialist referrals, please list all of the specialists required in the description field below"}
								warning
							/>
						}

						<Section.Content>
                            {["medcert"].includes(req_type) && (
								<p>
									Please provide additional information to
									help our doctors review your request. This
									will not appear on the Medical Certificate.
								</p>
                            )}

							{ !['docrefr', 'pathoreq'].includes(script_type) &&
								<>
									{!['medcons','medbuy', 'medclick'].includes(script_type) && req_type != 'cosm' && <UniFieldSUI fl={fields.cons_phone} {...args} />}
									{!hide_desc && <UniFieldSUI fl={fields.cons_desc} {...args} />}
								</>
							}

							{ ['dcs_cert'].includes(req_type) &&
								<UniFieldSUI fl={fields.dcs_cert_dates} {...args} />
							}

							{ ['dcs_scr'].includes(req_type) && <>
								<UniFieldSUI fl={fields.dcs_med_desc} {...args} />
							</>}

							{ ['dcs_refr'].includes(req_type) && <>
								<UniFieldSUI fl={fields.dcs_refr_spec} {...args} />
								<UniFieldSUI fl={fields.dcs_refr_dets} {...args} />
							</>}

							{ ['dcs_patho'].includes(req_type) && <>
								<UniFieldSUI fl={fields.dcs_patho_dets} {...args} />
							</>}

							{ ['docrefr'].includes(script_type) && 
								<>
									<span>If you have a particular doctor you would like the referral addressed to, please enter their name below (optional)</span>
									<UniFieldSUI fl={fields.cons_ref_name} {...args} show_label={false} />
									{/*<UniFieldSUI fl={fields.cons_ref_email} {...args} />*/}
								</>
							}

							{ ['docconsref', 'ileg'].includes(req_type) && 
								<>
									<UniFieldSUI fl={fields.cons_ref_spec} {...args} />
									<UniFieldSUI fl={fields.cons_ref_name} {...args} />
									<UniFieldSUI fl={fields.cons_ref_email} {...args} />
								</>
							}

							{['icann'].includes(req_type) && (
								<BookingFields
									type="icann"
									fallback={() => (
										<UniFieldSUI fl={fields.call_time} {...args} />
									)}
									resetFields={[
										{ name: "call_time", required: true },
									]}
									control={{fields, args}}
									ufThis={uf_this}
									setFields={setFields}
								/>
							)}

							{ ['mhcp'].includes(req_type) && 
								<>
									<BookingFields
										type="mhcp"
										fallback={() => (
											<UniFieldSUI fl={fields.dontcall_time} {...args} />
										)}
										resetFields={[
											{ name: "dontcall_time", required: false },
										]}
										control={{fields, args}}
										ufThis={uf_this}
										setFields={setFields}
									/>
									<UniFieldSUI fl={fields.cons_psy_known} {...args} />
									{values.cons_psy_known === 'yes' ? <>
										<UniFieldSUI fl={fields.cons_psy_name} {...args} />
										<UniFieldSUI fl={fields.cons_psy_email} {...args} />
									</> : <p>
										{app.user?.prof?.yogp?.name ? <>We will forward the details of this Mental Health Care Plan to your GP, <b>{app.user?.prof?.yogp.name}, {app.user?.prof?.yogp.organisation}</b>. If you wish to change your GP, you can do so by editing your profile.</> 
										: <>
											As you have not provided us the details of your psychologist, 
											we will provide a referral to our telehealth psychology partner <a href="https://www.mymirror.com.au/" target="_blank" className="underline text-blue-600">My Mirror</a>.
											You can provide us with the details of your psychologist by editing your profile above.
										</>}
									</p>}
								</>
							}

							{ has_bmi_requirement && <CheckBmi {...{fields, med_data, ...args}} /> }

							{['docconswl'].includes(req_type) && (
								<BookingFields
									type="docconswl"
									fallback={() => (
										app.site_status?.booking?.types?.docconswl?.enabled
										? (
											<div className="py-4">
												Next available consultation.
												<br />
												One of our doctors will get back to your soon.
											</div>
										) : null
									)}
									resetFields={[]}
									control={{fields, args}}
									ufThis={uf_this}
									setFields={setFields}
								/>
							)}


							{ ['docconssc'].includes(req_type) && 
								<>
									<UniFieldSUI fl={fields.cons_sc_day} {...args} />
									<UniFieldSUI fl={fields.cons_sc_length} {...args} />
								</>
							}
							{ ['docconssl'].includes(req_type) &&
								<>
									<UniFieldSUI fl={fields.cons_bmi_height} {...args} valid_not_required={true} />
									<UniFieldSUI fl={fields.cons_bmi_weight} {...args} valid_not_required={true} />
								</>
							}

							
							{app.acl.is_spon &&
								user_model.check_access("phts_add") && (
									<div className="field">
										<label>
											Please include any documents to support your request.
										</label>
										<Photos
											acceptedTypes={[
												{
													value: ".pdf",
													label: "PDF",
												},
												{
													value: "image/*",
													label: "PNG, JPG",
												},
												{
													value: ".doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
													label: "DOCX",
												},
											]}
											uploadType="attachment"
											show_disclaim
											inline
											target="uplass"
											data={values["phts"] || []}
											onChange={(ps) =>
												uf_this.handleInputChange(
													null,
													{ name: "cons_phts", value: ps }
												)
											}
										/>
									</div>
								)}

						</Section.Content>
				</Section>;
			}}
		</UniFormMed>
	);
};

export default DocCons;
