import React, {useState, useEffect } from 'react';
import { List, Input, Button, Table, Container, Segment, Header, Form, Grid } from 'semantic-ui-react'

const ObjectEditorItem = ({children}) => (
	<Grid.Row columns={1} >
			<Grid.Column>
				{children}
			</Grid.Column>
	</Grid.Row>
);


const ObjectEditor = ({controls=[], value, onChange, name, fl, changeStructure=true, autoSave=true, shouldRenderItem=_=>true,EditorItem=ObjectEditorItem,divided='vertically',...restOfProps }) => {
	const [type,setType] = useState(null)
	const [current,setCurrent] = useState(value||[])
	
	useEffect(_=>{
		setCurrent(value||[])
	},[value])
	
	
	function setUpdate(value, nosave=false) {
		onChange?.({target:{name:name || fl && fl.name, value}}, nosave)
		setCurrent(value)
	}

	// console.log({controls, current0:current?.[0], value0:value?.[0]});

	return <Form>
		<Grid >
			{current.map((v,i)=>{
				const Control = controls.find(c=>c.description==v.type) || null
				if (!shouldRenderItem(v))
					return null
			return <EditorItem  key={i}>
					{Control && <Control value={v} index={i}
							onUpdate={value=>{
								const updated = [...current]
								updated[i] = {...updated[i],...value}
								setUpdate(updated, !autoSave)
							}}
							{...restOfProps}
							>
							{changeStructure && <Button icon="x"
								color="red"
								onClick={_=>{
									if (!confirm('Delete entry?'))
										return
									const updated = [...current]
									updated.splice(i,1)
									setUpdate(updated)
								}}
								/>}
						
						</Control> || <div>Cannot find control {v.type}</div>}
						
						{!autoSave && <Button
							primary
							fluid
							onClick={_=> setUpdate(current) }
							content='Save'
						 />}
					
					</EditorItem>
				
			})}
			{changeStructure && <Grid.Row columns={2}>
			
				<Grid.Column>
					<Form.Select 
						fluid
						clearable
						placeholder="Add definition"
						options={controls.map(c=>({ key: c.description, value: c.description, text: c.description }))}
						value={type}
						onChange={(e, { value }) => setType(value)}
					/>
				</Grid.Column>
				<Grid.Column>
					<Button content="Add"
						disabled={!type}
						onClick={_=>{
							const Control = controls.find(c=>c.description==type)
							setUpdate([...current,{type,...Control.defaultValue}], !autoSave)
						}}
						/>
					
				
				</Grid.Column>
			</Grid.Row>}
		
		</Grid>
		
	</Form>
}

export default ObjectEditor;
