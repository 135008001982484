
const sortArrayOfObjectsByKey = (array, key, direction, numeric = false) => {
  return array.sort(function (a, b) {
    const x = a[key]
    const y = b[key]
    const compare = (numeric)
      ? ((Number(x) < Number(y)) ? -1 : ((Number(x) > Number(y)) ? 1 : 0))
      : ((x < y) ? -1 : ((x > y) ? 1 : 0))
    //Ascending or descending
    if (direction === 'des')
      return compare * -1
    else
      return compare
  })
}

export default sortArrayOfObjectsByKey