import React, { useMemo, useState } from 'react';
import menu_model from '../../../models/menu_model';
import { Accordion, ListItem, List } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../../../xAppLib/Hooks';

export const MENU_WIDTH = '380px';

const IcosmSideMenu = ({ collapse = false }) => {
	const [active, setActive] = useState();
    const menu = menu_model.get_ic_side_menu();
    const location = useLocation();
    const user = useUser();
    const orgName = (user.claims.nurse || app.acl.is_nurse ) && app.dvc?.org?.name ? app.dvc?.org?.name : user.org?.name;
    const email = user.org?.email || user.user_det?.email || user.prof?.email;

		const panels = useMemo(() => menu.map((item, index) => {
			const inView =
				location.pathname === item.link ||
				!item.excludeLinks?.includes(location.pathname) && item.activeLinks?.some?.((l) => l.includes('*') ? location.pathname?.startsWith(l.split('*')[0])  : location.pathname === l) ||
				item.sublinks?.some?.(({ link }) => location.pathname === link);
			const isOpen = (collapse || inView || active === index) && item.sublinks?.length;
			return {
				key: item.name,
				title: { content: <p className={inView ? 'item-active' : ''}>{item.name}</p> },
				...(isOpen && {
						content: (
							<List>
								{item.sublinks.map((sublink) => ( 
									<ListItem key={sublink.name} as={Link} active={location.pathname === sublink.link} to={sublink.link} content={sublink.name} onClick={sublink.act} />
								))}
							</List>
						)
					}),
				active: isOpen
			};
		}),[active, location, menu]);

		const handleOnTitleClick = (_, { index }) => { 
			menu[index]?.link && app.history.push(menu[index]?.link);
			setActive((prev) => index === prev ? undefined : index);
		};

    if (!orgName) return null;
		
    return (
			<>
				<div className="mb-6 flex flex-col items-center ">
					<div className="w-fit text-icosm-primary">
						<h5 className="!text-lg !leading-5 !mb-2 font-semibold">{orgName}</h5>
						<p className="font-semibold">{email}</p>
					</div>
				</div>
				<Accordion className="side-menu" onTitleClick={handleOnTitleClick} panels={panels} />
			</>
		);
}

export default IcosmSideMenu;
