import React, { useState, useEffect } from 'react';
import {Dropdown } from 'semantic-ui-react'
import obj_diff from 'xAppLib/helpers/obj_diff'
import cat_model from '../../models/cat_model';


const ProductSelect = React.memo(({ onChange, value, name, fl, fluid = true, cat_filter, multiple = false, clearable = true, placeholder = 'Start typing...', disc_allow_meds }) => {
	const [options,setOptions]=useState([])

	useEffect(async _=> {
		let tmp 
		if (cat_filter) {
			tmp = Object.keys(cat_filter)
					.sort( (a, b) => cat_filter[a]?.par_nm?.localeCompare(cat_filter[b].par_nm) )  
					.filter(k=>cat_filter[k].t=='m')
					.map( (k, i) => ({ key: 'md'+k+i, value: k, text: `${cat_filter[k].par_nm} >> ${cat_filter[k].active==false && '!' || ''} ${cat_filter[k].n || ''}` })) || [] 
		} else {
			const all_meds = (await cat_model.get_cat_list({all: true, cat_nm: 'cosm'}, false)).cat_show_list ?? [];
			let keys = all_meds && Object.keys(all_meds)
			// if (cat_filter) 
			// 	keys = keys.filter( k=> cat_filter.hasOwnProperty(k))
			if (disc_allow_meds?.length) 
				keys = keys.filter( k=> disc_allow_meds.includes(k))
			tmp = keys
						.sort( (a, b) => all_meds[a]?.name?.localeCompare(all_meds[b].name) )
						.map( (k) => {
							const { l, n, t, active } = all_meds[k];
							return !['c','s'].includes(t) && active !== false ? ({ key: l+k, value: k, text: n }) : false
						}).filter(Boolean) || []
		}

		setOptions(tmp)
	},[cat_filter, disc_allow_meds])
	
	// console.log('ProductSelect', value);
	return <Dropdown
	    placeholder={placeholder}
	    fluid={fluid}
	    multiple={multiple}
	    search
	    selection
		clearable={clearable}
	    options={options}
		value={multiple&&!value ? [] : value}
		onChange={(e, { value }) => onChange({target:{name:name || fl && fl.name, value}})}
	  />
},(prevProps, nextProps) => {
	const diff = obj_diff(prevProps.cat_filter,nextProps.cat_filter)
	return prevProps.value===nextProps.value && Object.keys(diff.to).length == 0
});

export default ProductSelect
