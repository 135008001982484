import React from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import { ViewEditDets } from "xAppLib/DataList";
import inventory_model from "../../models/inventory_model";
import { useAsyncUpdate } from "../../xAppLib/Hooks/useAsync";
import { toast } from "react-toastify";

export default function AddInventory({ setRefresh, isOpen, onClose }) {
	const save = useAsyncUpdate(async (d) => await inventory_model.add_item({ ...d, supplier: { supp_name: d.supplier } }));

	return <Modal open={isOpen} onClose={onClose} closeIcon>
		<Modal.Header>
			<Header>Add item to inventory</Header>
		</Modal.Header>
		<Modal.Content>
			<ViewEditDets
				newRecord={true}
				det_fields={inventory_model.edit_fields}
				onSave_dets={d => {
					save.fn(d)
						.then(() => {
							setRefresh(true);
							onClose();
							toast.success('Product added to inventory', {
								containerId: 'default',
								position: 'bottom-center',
							});
						}).catch(error => {
							toast.error(error.toString(), {
								containerId: 'default',
								position: 'bottom-center',
							});
						});
				}}
				loading={save.loading}
				onChange={(name, value) => {
					if (inventory_model.edit_fields.filter(f => f.name === name)?.[0]?.type === 'number')
						return { [name]: Number(value) };
				}}
				btn_submit={<Button loading={save.loading} className="cosm-button-dark" content="Add to Inventory" />}
				btn_cancel={<span></span>}
			/>
		</Modal.Content>
	</Modal>
}