
import React, { useState,  useRef, useEffect, createRef } from 'react';
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { cls as clsn } from 'views/NUI/utils'
import { Link } from "react-router-dom";
import menu_model from 'models/menu_model'
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';
import { Container } from 'semantic-ui-react';

const tiles = {
	grow: {
        img: "2/Rectangle306.jpg",
        list: [
            {
                title:'Become a Cosmetic Nurse',
                link:"/nurse"
            },
            {
                title:'Grow your Aesthetics Business',
                link:"/grow"
            },
    ]
    },
    support: {
        img: "2/Rectangle432.jpg",
        list: [
            {
                title: 'Medical Oversight & Complication Management',
                link: "/medical-oversight"
            }, 
            {
                title: 'Fast & Effective Software', 
                link: "/effective-software"
            },
            {
                title: 'Compliance & Safety', 
                link: "/complicance"
            },
            {
                title: 'Training & Events', 
                link: "/training-events"
            },
            {
                title: 'Cosmetic Support', 
                link: "/support"
            }]
    },
    why: {
        img: "2/Rectangle434.jpg",
        list: [
            {
                title: "Our Values",
                link: "/our-values"
            }, 
            {
                title: "Contact Us",
                link: "/contact"
            }
        ]
        
    },
    resources: {
        img: "2/Rectangle435.jpg",
        list: [
            {
                title: 'FAQs',
                link: "/instantcosmetics-faqs"
            }
        ]
    }
}

// 		--------------------------------		--------------------------------		---------

const  IcosmMegaMenu = ({inSideMenu, cls='main', setMainSideMenu }) => {
	const [current, setCurrent] = useState();
    const btnRef = useRef([]);
	const [sideMegaMenu, setSideMegaMenu] = useState(true)
	const [popperElement, setPopperElement] = useState()
	const {styles, attributes} = usePopper(btnRef, popperElement)
	const [mainMenu, setMenu] = useState([])
	const [panel, setPanel] = useState()
    const img_path = "https://storage.googleapis.com/icosm/"

	useEffect(() => {
		const menu = menu_model.get_menu(inSideMenu, '/', 0, 0, true);
		setMenu(menu)
	}, [inSideMenu])
	
	const handleShowMenu = (val) => {
		setSideMegaMenu(val)
		setMainSideMenu?.(val)
	}

	const handleOpen = (open) => {
		useEffect(() => {
			!open && handleShowMenu(true)
		}, [open])
	}

    const handleHover = (isOpen, i) => {
        !isOpen && btnRef.current[i].click()
	}

	// 		--------------------------------		--------------------------------		---------

	useEffect(() => {
		setPanel(
		    <Popover.Panel 
				ref={popperElement}
				className={clsn(inSideMenu ? "side-menu h-full overflow-y-auto pb-24 mt-[72px]" 
				: "mt-[119px] lg:px-9", "panel !bg-icosm-quaternary h-[255px] w-full shadow-lg z-50 !top-0",)}
				style={styles.popper}
				{...attributes.popper}
				>
                <Container className="!pt-0">
                    {tiles[current] && 
                        <div className={clsn(!inSideMenu && "flex py-[17px] gap-9", "relative w-full mx-auto max-w-screen-menu-xl")}>
                            {/* side section */}
                            <div style={{ backgroundImage: `url(${img_path}${tiles[current].img})`}}  className={clsn(inSideMenu && "hidden", "max-w-full lg:w-[289px] h-[221px] bg-cover" )}/>

                            {/* main section */}
                            <div className="section-main col-span-2 h-full">
                                <div className={clsn(!inSideMenu ? "items-start": "h-full", "list flex flex-wrap flex-col")}>
                                    {tiles[current]?.list.map((el, i) => 
                                            <Popover.Button 
                                                key={i} as={Link} to={el.link}
                                                onClick={() => {inSideMenu && handleShowMenu(true)}} 
                                                className="md:mb-4 text-icosm-primary hover:underline hover:text-icosm-tertiary text-[1.2rem] md:text-[1rem]">
                                                {el.title}
                                            </Popover.Button>)
                                        }
                                    </div>
                                
                            </div>
                        </div>
                    }
                </Container>
			</Popover.Panel>)
	}, [current])

   // 		--------------------------------		--------------------------------		---------

   return (
	<Popover.Group className={`flex justify-between w-full lg:ml-[80px] mr-4 mega-menu ${inSideMenu ? "flex-col" : "flex-row items-center"}`}>

	{mainMenu.length > 0  && mainMenu?.filter(Boolean).map((el, i) => {
	  return <Popover key={i}>
		{({ open, close }) => (

		  <>
		    {/* Main Menu */}
			{inSideMenu && handleOpen(open)}

			{inSideMenu && !sideMegaMenu && 
				<span className="text-is-black"><button onClick={() => {
					handleShowMenu(true)
					close(btnRef)}} 
					className="btn back flex items-center bg-none w-1/2 absolute top-[8px] left-6 py-4 cursor-pointer"
					>
					<SIcon name="chevron" size="xxs" svg_cls='rotate-90 mr-1' bold/>
					Back
				</button></span>
			}

		    <div className="relative md:z-10">
				<div className={clsn(inSideMenu ? "px-0" : "xl:px-1.5 2xl:p-2")}
                 
                >
					<Popover.Button
						key={i}
                        ref={el => btnRef.current[i] = el}
                        onMouseEnter={() => !inSideMenu && handleHover(open, i)}
						onClick={() => {
							setCurrent(el.key)
							inSideMenu && handleShowMenu(false)
						}}
                        className={clsn(cls, el.cls,
						inSideMenu && !sideMegaMenu && "hidden",
						!inSideMenu && "items-center uppercase text-[11px] text-white",
						inSideMenu ? "text-[1.2rem] font-bold side-btn w-full text-black p-4" : "hover:text-icosm-gold-50 rounded-md text-[11px]",
						open ? "open underline" : "", 
						"popover-btn button group flex inline-flex items-end ")}>
						<span className="max-w-[80%] text-left sm:max-w-none sm:text-center">{el.name}</span>
					</Popover.Button>
				</div>
		    </div>

		    {/* Dropdown Menu */}
			{ panel }

		  </>
	 	)}
	  </Popover>     
	})}
	</Popover.Group>

  )
}

export default IcosmMegaMenu
