import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet"
import { Popup, Button, Container, Grid, GridRow, GridColumn, Segment, Header } from "semantic-ui-react";
import { ObjectInspector, chromeLight } from 'react-inspector';
import { DataConnector, ListTable, ListFilters, ListPagination } from 'xAppLib/DataTable';
import inventory_model from "../../models/inventory_model";
import sortArrayOfObjectsByKey from "../../xAppLib/helpers/sortArrayOfObjectsByKey";
import { useParams, useSearchParams } from "react-router-dom";
import CSV_helper from "../../xAppLib/helpers/CSV_helper";
import { useAsync, useAsyncUpdate } from "../../xAppLib/Hooks/useAsync";
import user_model from "../../models/user_model";
import ClinicSelector from "./ClinicSelector";

export default function DrugBook() {
	const [pageSize, setPageSize] = useState(50);
	const [page, setPage] = useState(1);
	const [product, setProduct] = useState();
	const [batches, setBatches] = useState([]);
	const [batch, setBatch] = useState();
	const { pid, iid } = useParams();
	const [searchParams,] = useSearchParams();
	const [oid, setOid] = useState(searchParams.get('oid'));

	const fields = useMemo(() => inventory_model.drugbook_fields, []);

	const isAdmin = useMemo(() => user_model.check_access('inventory_admin'), []);

	const items = useAsyncUpdate(async ({pid, iid, oid}) => 
		sortArrayOfObjectsByKey(
			Object.values(
				await inventory_model.drugbook({ ...(oid ? { oid } : {}), ...(pid ? { pid } : {}), ...(iid ? { iid } : {}) }) || []
			).reduce((acc, i) => [...acc, ...i], []), 
			'date', 
			'des'
		)
	);

	const { data: products } = useAsync(async () => await inventory_model.get(null, oid || searchParams.get('oid')), [oid]);

	useEffect(() => {
		items.fn({ pid, iid, oid });
		pid && setProduct(pid);
	}, []);

	useEffect(() => {
		// Set Batch
		if (product && products) {
			let batches = [{ key: 'all', value: 'all', text: 'All' }];

			const productBatches = products.find(p => p[0].pid === product);
			productBatches && (batches = [...batches, ...productBatches.map(b => ({ key: b.iid, value: b.iid, text: b.batch_number }))]);
			setBatches(batches);

			iid && setBatch(batches.find(b => b.key == iid)?.key || 'all');
		}
	}, [product, products]);

	const downloadCsv = useCallback(() => {
		if (items?.data) {
			const file = CSV_helper.json2csv(items.data.map(f => {
				let item = {};
				fields.map(({ name, jpath }) => {
					let value = jpath ? f[jpath] : '';
					if (name === 'Nurse')
						value = [f.nurse_name, f.nurse_email].join(' - ');
					item[name] = value;
				});
				return item;
			}));
			CSV_helper.download(file, `drugbook_${new Date().toISOString()}`);
		}
	}, [items]);

	return <Container className='wide-content' fluid style={{paddingBottom: 100}}>
		<Helmet>
			<title>Inventory Management System</title>
		</Helmet>

		<h1 className="text-is-blue text-xl">Drug Book</h1>

		{isAdmin && <ClinicSelector oid={oid} setOid={setOid} refresh={() => null} />}

		<Segment>
			<Segment.Group horizontal>
				<Segment color='black'>
					<Header>Product</Header>
					<ListFilters.Filter
						onFilterChange={s => setProduct(s.product_filter)}
						multiple={false}
						name="product_filter"
						value={product}
						as='dropdown'
						placeholder="Filter by Product"
						options={[
							{ key: 'all', value: 'all', text: 'All' },
							...(products?.map((i) => ({ key: i[0].pid, value: i[0].pid, text: i[0].prod.name })) || [])
						]}
					/>
				</Segment>
				<Segment color='black'>
					<Header>Batch</Header>
					<ListFilters.Filter
						onFilterChange={s => setBatch(s.batch_filter)}
						multiple={false}
						name="batch_filter"
						value={batch}
						as='dropdown'
						placeholder="Filter by Batch"
						options={batches}
					/>
				</Segment>
			</Segment.Group>
			<div className="text-right">
			<Button
				content="Filter"
				type="button"
				className="cosm-button-dark"
				size="mini"
				loading={items.loading}
				onClick={() => items.fn({ pid: product, iid: batch, oid })}
			/>
			</div>
		</Segment>

		<DataConnector
			pageSize={pageSize}
			page={page}
			data={items.data || []}
		>
			{(data, _, pages, total, pageSize) => {
				return <>
					<ListPagination
						page={page}
						pages={pages}
						pageSize={pageSize}
						loading={items.loading}
						data={data}
						total={total}
						boundaryRange={3}
						showPageSizeSelector={true}
						onPageSizeChange={setPageSize}
						onPageChange={setPage} />

					<ListTable data={data} loading={items.loading}>
						{(row, index) => {
							return <>
								<ListTable.Cell row={row} index={index} label='No'>
									{(value, r, index) => (index + 1 + (page - 1) * pageSize)}
								</ListTable.Cell>

								{fields.map((f, fi) => (
									<ListTable.Cell row={row} index={index} key={fi} f={f} />
								))}

								<ListTable.Cell
									visible={false}
									// visible={user_model.check_access('inventory_admin')}
									row={row} index={index} label='Full data'
								>
									{(value, row) => (<Popup
										trigger={<Button icon='add' />}
										position='left center'
										on='click'
										style={{ height: '80%', width: '80%', minWidth: 700, overflow: 'scroll' }}
									>
										<ObjectInspector
											data={row}
											expandLevel={2}
											theme={{ ...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 }) }}
										/>
									</Popup>)}
								</ListTable.Cell>
							</>
						}}


					</ListTable>

					<ListPagination
						page={page}
						pages={pages}
						pageSize={pageSize}
						loading={items.loading}
						data={data}
						total={total}
						boundaryRange={3}
						showPageSizeSelector={true}
						onPageSizeChange={setPageSize}
						onPageChange={setPage} />

					<Button
						content="Download CSV"
						icon="download"
						type="button"
						size="mini"
						className="cosm-button-dark"
						style={{ float: 'right' }}
						onClick={downloadCsv}
					/>
				</>
			}}
		</DataConnector>
	</Container>
}