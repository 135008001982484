import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet"
import { Popup, Button, Container, Icon } from "semantic-ui-react";
import { ObjectInspector, chromeLight } from 'react-inspector';
import { DataConnector, ListTable, ListPagination } from 'xAppLib/DataTable';
import inventory_model from "../../models/inventory_model";
import AddInventory from "./AddInventory";
import { useAsync } from "../../xAppLib/Hooks/useAsync";
import user_model from "../../models/user_model";
import ClinicSelector from "./ClinicSelector";

export default function InventoryList() {
	const [pageSize, setPageSize] = useState(50);
	const [page, setPage] = useState(1);
	const [refresh, setRefresh] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [oid, setOid] = useState(null);

	const isAdmin = useMemo(() => user_model.check_access('inventory_admin'), []);

	const fields = useMemo(() => inventory_model.list_fields, []);

	const loadInventory = useAsync(async () => inventory_model.formatDbData(await inventory_model.get(null, oid)), [refresh, oid]);

	return <Container className='wide-content pb-[50px]' fluid>
		<Helmet>
			<title>Inventory Management System</title>
		</Helmet>

		{isAdmin && <ClinicSelector oid={oid} setOid={setOid} refresh={refresh} />}

		{isAdmin && !oid ? (
			<p className="font-bold text-center my-6">
				Select a clinic to view inventory
			</p>
		) : <>
			{isAdmin ? <>
				<h1 className="mb-0 mt-[48px]">Clinic Inventory</h1>
			</> : (
				<div className="mb-[48px] flex items-center justify-between">
					<div>
						<h1 className="mb-0">Clinic Inventory</h1>
						<p>Add new inventory and manage your stock levels</p>
					</div>
					<div>
						<Button icon compact size={"big"} color="green" onClick={_ => setShowModal(!showModal)}>
							<Icon name='plus' /> Add Inventory
						</Button>
					</div>
				</div>
			)}
			<DataConnector
				pageSize={pageSize}
				page={page}
				refresh={refresh}
				data={loadInventory.data}
			>
				{(data, _, pages, total, pageSize) => {
					return <>
						<ListPagination
							page={page}
							pages={pages}
							pageSize={pageSize}
							loading={loadInventory.loading}
							data={data}
							total={total}
							boundaryRange={3}
							showPageSizeSelector={true}
							onPageSizeChange={setPageSize}
							onPageChange={setPage} />

						<ListTable data={data} loading={loadInventory.loading}>
							{(row, index) => {
								return <>
									<ListTable.Cell row={row} index={index} label='No'>
										{(_value, _r, index) => (index + 1 + (page - 1) * pageSize)}
									</ListTable.Cell>

									{fields.map((f, fi) => (
										<ListTable.Cell row={row} index={index} key={fi} f={f} oid={oid} refresh={() => setRefresh(new Date())} />
									))}

									<ListTable.Cell
										visible={false}
										row={row} index={index} label='Full data'
									>
										{(_value, row) => (<Popup
											trigger={<Button icon='add' />}
											position='left center'
											on='click'
											style={{ height: '80%', width: '80%', minWidth: 700, overflow: 'scroll' }}
										>
											<ObjectInspector
												data={row}
												expandLevel={2}
												theme={{ ...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 }) }}
											/>
										</Popup>)}
									</ListTable.Cell>
								</>
							}}


						</ListTable>

						<ListPagination
							page={page}
							pages={pages}
							pageSize={pageSize}
							loading={loadInventory.loading}
							data={data}
							total={total}
							boundaryRange={3}
							showPageSizeSelector={true}
							onPageSizeChange={setPageSize}
							onPageChange={setPage} />
					</>
				}}
			</DataConnector>
		</>}

		<AddInventory setRefresh={setRefresh} isOpen={showModal} onClose={_ => setShowModal(false)} />
	</Container>
}