import React, { useEffect } from 'react';
import { Form, Checkbox, Loader } from 'semantic-ui-react';
import script_model from 'models/script_model';
import UniFormMed from 'views/med/UniFormMed';

import currency from 'currency.js';

const getPricingLevel = (cart, med_data) => {
  if ((cart.has_concession || cart.has_dva_concession) && !med_data?.epresc?.PrivatePrescription === true) return 'concession';
  if (cart.has_medicare && !med_data?.epresc?.PrivatePrescription === true) return 'medicare';
  return 'original';
};

const getEvermedMedicationPrice = (pricingLevel, medication) => {
  if (pricingLevel === 'concession' && medication.totalPrices.concession) return medication.totalPrices.concession;
  if (pricingLevel === 'medicare' && medication.totalPrices.general) return medication.totalPrices.general;
  return medication.totalPrices.original;
};

const MedSelection = (props) => {
  const { Section, cart, evermed, inline = false, med_data, has_medicare, script_type, hide_section=false} = props;
  const pricingLevel = getPricingLevel(cart, med_data);
  const medicare_needs_updating = pricingLevel === 'medicare' && !has_medicare && ['medbuy', 'exprbuy', 'qualbuy', 'medclick', 'exprclick', 'qualclick'].includes(script_type);
  
  return (
    <UniFormMed {...props} section="medselection" fields={script_model.EM_MEDICATION_FIELDS}>
      {(values, valids, uf_this, fields) => {
         if(!hide_section) {
          return (
          <Section>
            <Section.Header className={inline ? 'm-0 mb-3 text-lg' : ''}>Medication selection</Section.Header>
            <Section.Content className={inline ? 'p-0' : ''}>
              <Form.Field label="Which brand of medication would you like to receive?" />

              <Form.Field error={(valids && !valids.medication) || medicare_needs_updating}>
                <MedSelector {...{ evermed, uf_this, cart, values, med_data, pricingLevel, medicare_needs_updating }} />
              </Form.Field>
            </Section.Content>
          </Section>
        )}
      }}
    </UniFormMed>
  );
};

const MedSelector = ({ evermed, uf_this, values, medicare_needs_updating, pricingLevel }) => {
  const handleMedSelection = (productVariant, drugBrandName) => {
    uf_this.handleInputChange(null, { name: 'medication', value: productVariant });
    uf_this.handleInputChange(null, { name: 'selected_brand', value: productVariant !== 'Generic' ? drugBrandName : undefined });
  };

  useEffect(() => {
    if (medicare_needs_updating) {
      uf_this.handleInputChange(null, { name: 'medication', value: null });
      uf_this.handleInputChange(null, { name: 'selected_brand', value: null });
    }
  }, [medicare_needs_updating]);

  // Clear values on unmount
  useEffect(() => {
    return () => {
      uf_this.handleInputChange(null, { name: 'medication', value: null });
      uf_this.handleInputChange(null, { name: 'selected_brand', value: null });
    }
}, [])

  const medicationOptions = evermed.m || [];
  return (
    <>
      {evermed.loading && (
        <div className="flex-row space-x-2">
          <Loader inline active size="tiny" />
          <span>Loading medication options...</span>
        </div>
      )}
      {!evermed.loading && evermed?.medRes !== 'ok' && (
        <p className="text-red-600 font-semibold">
          There was an issue loading medication options from our supplier. Please select Pick-up from pharmacy or try again later.
        </p>
      )}
      {medicationOptions.length > 0 &&
        medicationOptions.map((opt) => (
          <Checkbox
            key={opt.productVariantType}
            className="w-full my-2"
            radio
            label={medLabel(pricingLevel, opt)}
            name="medication"
            disabled={medicare_needs_updating}
            value={opt.productVariantType}
            data-testid="radio-medication"
            checked={values.medication === opt.productVariantType}
            onChange={() =>
              medicare_needs_updating ? console.log('selectionAttemptedWithBadMedicareDetails') : handleMedSelection(opt.productVariantType, opt.drugBrandName)
            }
          />
        ))}
      {medicationOptions.length > 0 && medicare_needs_updating && (
        <p className="text-red-600 mt-1">
          We could not verify your medicare details. Please ensure your name, date of birth, Medicare number and Medicare reference number match your Medicare card.
          Alternatively, you can remove your medicare details and be charged the non-medicare price.
        </p>
      )}
    </>
  );
};

const medLabel = (pricingLevel, opt) => (
  <label>
    <div className="flex w-full justify-between">
      <span>
        {opt.drugBrandName} ({opt.productVariantType !== 'Generic' ? 'Branded' : 'Generic'}){' '}
      </span>
      <span className="font-semibold">{currency(getEvermedMedicationPrice(pricingLevel, opt)).format()}</span>
    </div>
  </label>
);

export default MedSelection;
