import superagent from 'superagent'

import logger from '../libs/logger'

const DEBUG = false;

export default class API_service {

	static get API_BASE_URL() { 
		return app.settings.dev_env_remote2 && app.app_data.ws_conf.loc.DEV2_API__SCRIPT_BASE__url 
				|| app.settings.dev_env_remote3 && app.app_data.ws_conf.loc.DEV3_API__SCRIPT_BASE__url 
				|| app.settings.dev_env_remotecosm && app.app_data.ws_conf.loc.DEVCOSM_API__SCRIPT_BASE__url 
				|| app.settings.dev_env_remote && app.app_data.ws_conf.loc.DEV_API__SCRIPT_BASE__url 
				|| app.app_data.ws_conf.loc.API__SCRIPT_BASE__url; 
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async call_api (loc, req_prs = null, ca = null, ce = null, binary = false, vars = {}) {

		DEBUG && loc!='lg' && console.log('API_service :: call_api ', loc, req_prs, ca, ce, JSON.stringify(req_prs))

		if (req_prs && Array.isArray(req_prs))
			req_prs = { ...req_prs }

		const call_url = (!loc.startsWith('https://') && !loc.startsWith('http://') && this.API_BASE_URL ||'') + loc

		let supa

		if ( req_prs) {
			const feparams = {}
			for(let [k,v] of app.runtime.get_params_init) {
				feparams[k] = v
			}
			if (app.runtime.ref_init) {
				feparams.referrer = app.runtime.ref_init
			}
			if (Object.keys(feparams).length) {
				req_prs.req_xtra = {
					...req_prs.req_xtra,
					feparams
				}
			}
		}

		if ( req_prs )
			supa = superagent
				.post(call_url)
				.send(req_prs || {})
				.type(vars.req_type || (req_prs ? 'json' : 'form'))

		else
			supa = superagent
				.get(call_url)

		if (binary) 
			supa.responseType('blob')

		const token = await app.user.idtoken()
			
		if (!vars.no_hdr) {
			supa
				.set('Authorization', 'Bearer ' + token)
				//.withCredentials()
				.set('sh', app.shid)
				.set('rtm', app.runtime.mode)
				.set('rta', app.runtime.app)
				.set('fe', app.settings.is_ionic?'ia':'wa')
				.set('dvc', app.dvc?.dvcid || 'none')
				.set('vr', __GIT_HASH__ + ' ' + __BUILD__)
			if (vars.n)
				supa.set('n',vars.n)
			if (vars.rc)
				supa.set('rc',vars.rc)
		}

		supa
				.end( 
						async (err, res) => {


							if (err || res.body===undefined) {
								console.log("Error loading response from ", loc, " :: error : ", err, " :: res : ", res);
								if (loc!=app.app_data.ws_conf.loc.API__lg.u)
									logger.report_error('ERROR Call API', 'error', {err, res});
								if (res?.body?.err?.expired) {
									await new Promise(resolve=>setTimeout(resolve,100))
									await app.user.idtoken(true)
									return this.call_api (loc, req_prs, ca, ce, binary, {...vars,n:(vars.n||0)+1})
								}

								if (res?.statusCode == 502) {
									if ((vars.rc||0) < 4) {
										await new Promise(resolve=>setTimeout(resolve,500))
										return this.call_api (loc, req_prs, ca, ce, binary, {...vars,rc:(vars.rc||0)+1})
									} else {
										logger.report_error('ERROR Call API 502 repeats more than 3', 'error', {err, res});
									}
								}


								if (res && res.body && res.body.error) 
									ce && ce(res && res.body && res.body.error)
								else if (res && res.statusCode < 500 && err)
									ce && ce(err.message)
								else
									ce && ce("Unable to process your request at this time.")
								
								return
							}

							if (res?.body?.shid) 
								app.shid = res.body.shid

							// console.log ('-- usg_log req: ', JSON.stringify(res.req._data))
							ca && ca(res.body);
						}
				 );

		return;
	}
	
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static api_promise(loc,req_prs) {
		return new Promise( (resolve, reject) => API_service.call_api(loc, req_prs, resolve, reject) )
	}

	// 		--------------------------------		--------------------------------		---------

	static load_data( loc, params, ca, ce, binary = false, vars ) {

		return new Promise( (resolve,reject) =>	this.call_api(
																loc,
																params,
																r => {
																	resolve(r)
																	ca && ca(r)
																},
																r => {
																	reject(r)
																	ce && ce(r)
																},
																binary,
																vars
															)
			)
		
	}


	static load_binary( loc, params = null, binary = false ) {

		return this.load_data(loc, params, null, null, true)
		
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

}
