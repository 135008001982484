import React from 'react';
import db_lib from 'xAppLib/libs/db_lib';
import API_service from 'xAppLib/providers/API_service'
import EPrescribing from '../views/admin/med/EPrescribing';
import SimpleShowMore from '../xAppLib/UIelems/SimpleShowMore';
import cat_model, { cat_nms } from 'models/cat_model';
import { Icon } from 'semantic-ui-react';

const _FRDB_LOC = 'meds';

const _DET_FIELDS = {

			'itm_tp': 'Item Type',
			'req_tp': 'Request Type (req_type)',

			'base_mid': 'Base med ID',
			'need_patho': 'Required Pathology',

			'name': 'name',
			'alt_name': 'Alt names',
			'ain': 'Active ingredient names',
			'size': 'size',
			'qnty': 'quantity',
			'dose': 'dosage',
			'repeats': 'repeats',
			'days_limit': 'script limit (in days)',
			'nmper_limit': 'total scrs number limit in 6 months',
			'authority_code': 'Authority code number (or "auto")',
			'phone_appr': 'Phone approval number',
			'extra': 'Extra text on script',

			'FU_days': 'Allow booking FU after (in days)',
			'FU_mid': 'MedID of the FU consult',

			'tp_mid': 'MedID of the Treatment Plan consult',
			'tp_alt': 'Other Treatment Plans on which this med is available',
			'tp_rpt': 'Treatment Plan Repeats', // e.g. { r:5, i:28, p:'d' } repeats 5 times every 28 days
			'tp_rpt_adj': 'Flexible Repeats', // Allow the doctor to set the repeats 2x normal. Boolean now, but could be a list of multipliers in future

			'xc_avail': 'Allow Express Consults',
			'need_qual': 'Requires Qualification Consult',
			'med_warn': 'Warning/Disclaimer to show on Med Req page',

			'mc_pr': 'Charge for medical consultation in App',
			'mc_pr_ref': 'Price Ref for medical consultation in App',
			'mc_mb_pr': 'Charge for medical consultation when MedBuy in App',
			'mc_ph_pr': 'Charge for medical consultation in Pharm Terminal',
			// 'medbuy_price': 'MedBuy medication price',
			// 'medbuy_conc_price': 'MedBuy concession medication price',
			// 'medbuy_nomedicare_price': 'MedBuy no Medicare card medication price',
			// 'medbuy_gen_price': 'MedBuy Generic medication price',
			// 'medbuy_gen_conc_price': 'MedBuy Generic concession medication price',
			// 'medbuy_gen_nomedicare_price': 'MedBuy Generic no Medicare card medication price',
			// 'medbuy_supp_oid': 'MedBuy supplier OID',

			
			'PBSCode': 'PBS Code',
			'PI': 'Product Information',
			'CMI': 'Consumer Medicines Information',
			
			'IL': 'Info Link',
			
			'xtra': 'extra configuration',
			'epresc': 'ePrescribing data fields',

			'tga_cat': "TGA Document Cat"
			// 'monthly_limit': 'monthly limit',
		}

const _COSMSCR_FIELDS = {
	itm_tp: _DET_FIELDS.itm_tp,
	base_mid: _DET_FIELDS.base_mid,
	name: _DET_FIELDS.name,
	size: _DET_FIELDS.size,
	qnty: _DET_FIELDS.qnty,
	treatment_areas: 'Treatment Areas',
	use_type: 'Use Type',
	dose: _DET_FIELDS.dose,
	days_limit: _DET_FIELDS.days_limit,
	extra: _DET_FIELDS.extra,
};

const _PATHO_FIELDS = {
	'itm_tp': 'Item Type',

	'name': 'name',
	'patho_req_txt': 'Pathology Request',
	'base_mid': 'Base med ID',
	'days_limit': 'script limit (in days)',
	'items': 'Tests',
	'instructions':'Additional instructions for patient',
	'definition': 'Definition',
	'itm_lock':'Tests cannot be chosen',
	'IL': 'Info Link',
	'xtra': 'extra configuration',
}

const _MEDIMG_FIELDS = {
	'itm_tp': 'Item Type',
	'name': 'name',
	'req_txt': 'Medical Imaging Request',
	'req_time': 'Expected Request Time (days)',
}

const _ITEM_TYPE = [
	{ key: 'scr', value: 'scr', text: 'Script Request' },
	{ key: 'doccons', value: 'doccons', text: 'Doctor Consultation Request' },
	{ key: 'subcons', value: 'subcons', text: 'Treatment Plan Consult Request' },
	{ key: 'patho', value: 'patho', text: 'Pathology Request' },
	{ key: 'cert', value: 'cert', text: 'Medical Certificate Request' },
	{ key: 'refr', value: 'refr', text: 'Referral Request' },
	{ key: 'medimg', value: 'medimg', text: 'Med Img Request' },
	{ key: 'cosmscr', value: 'cosmscr', text: 'Cosmetic Treatment Request' },
]

const _COSM_PRODUCT_USE_TYPE = [
	{key: 'single_use', value: 'single_use', text: 'Single-use (mL)'},
	{key: 'redrawable', value: 'redrawable', text: 'Redrawable (u)'},
];

const _COSM_TREATMENT_AREA = Object.entries({
	'dao': 'DAO',
	'decolletage': 'Decolletage',
	'upper-chest': 'Decolletage (Upper Chest)',
	'nose-tip-elevation': 'Depressor Septi (Nose Tip Elevation)',
	'forehead': 'Frontalis (Forehead)',
	'full-face': 'Full Face',
	'full-face-superficial-injections': 'Full Face (Superficial Injections)',
	'frown-lines': 'Glabella (Frown Lines)',
	'hands': 'Hands',
	'underarms': 'Hyperhidrosis Axilla (Underarms)',
	'hyperhidrosis-hairline': 'Hyperhidrosis Hairline',
	'gummy-smile': 'LLSAN (Gummy Smile)',
	'lateral-face': 'Lateral Face',
	'lips': 'Lips',
	'marionette-lines': 'Marionette Lines',
	'masseters': 'Masseters (Teeth Grinding/Jaw Slimming)',
	'mental-crease-chin': 'Mental Crease (Chin)',
	'mentalis': 'Mentalis (Chin)',
	'mid-face': 'Mid-Face',
	'cheeks': 'Mid-Face (Cheeks)',
	'bunny-lines': 'Nasalis (Bunny Lines)',
	'nasal-flare': 'Nasalis (Nasal Flare)',
	'nasolabial-folds': 'Nasolabial Folds',
	'neck': 'Neck',
	'crows-feet': 'Orbicularis Oculi (Crow’s Feet)',
	'lip-flip-lipstick-lines': 'Orbicularis Oris (Lip Flip/Lipstick Lines)',
	'lip-lines': 'Perioral Lines (Lip Lines)',
	'piriform-fossa': 'Piriform Fossa',
	'neck-lines': 'Platysmal Bands (Nefertiti/Neck Lines)',
	'pre-jowl-sulcus': 'Pre Jowl Sulcus',
	'pre-auricular': 'Pre-Auricular',
	'double-chin': 'Submental Fat (Under the Chin Fat / Double Chin)',
	'under-eyes': 'Tear Troughs (Under Eyes)',
	'temples': 'Temples (Only for nurses trained in this area)',
}).map(([key, text]) => ({key, value: key, text}));


const _MB_FIELDS = {
			'medbuy_price': 'MedBuy medication price',
			'medbuy_conc_price': 'MedBuy concession medication price',
			'medbuy_nomedicare_price': 'MedBuy no Medicare card medication price',
			'medbuy_gen_price': 'MedBuy Generic medication price',
			'medbuy_gen_conc_price': 'MedBuy Generic concession medication price',
			'medbuy_gen_nomedicare_price': 'MedBuy Generic no Medicare card medication price',
			'medbuy_supp_oid': 'MedBuy supplier OID',
		}


const _TGA_DOCUMENT_CATS = [
	{ key: 0, value: null, text: 'None' },
	{ key: '1-cap', value: '1-cap', text: 'Category 1-CBD medicinal cannabis product (CBD≥98%) - Capsule' },
	{ key: '1-herb', value: '1-herb', text: 'Category 1-CBD medicinal cannabis product (CBD≥98%) - Herb, dried (for vaporisation)' },
	{ key: '1-inh', value: '1-inh', text: 'Category 1-CBD medicinal cannabis product (CBD≥98%) - Inhalation' },
	{ key: '1-oraliq', value: '1-oraliq', text: 'Category 1-CBD medicinal cannabis product (CBD≥98%) - Oral Liquid' },
	{ key: '1-patch', value: '1-patch', text: 'Category 1-CBD medicinal cannabis product (CBD≥98%) - Patch, dermal' },
	{ key: '1-topic', value: '1-topic', text: 'Category 1-CBD medicinal cannabis product (CBD≥98%) - Topical' },
	
	{ key: '2-cap', value: '2-cap', text: 'Category 2-CBD dominant medicinal cannabis product (CBD≥60% and less than 98%) - Capsule' },
	{ key: '2-herb', value: '2-herb', text: 'Category 2-CBD dominant medicinal cannabis product (CBD≥60% and less than 98%) - Herb, dried (for vaporisation)' },
	{ key: '2-inh', value: '2-inh', text: 'Category 2-CBD dominant medicinal cannabis product (CBD≥60% and less than 98%) - Inhalation' },
	{ key: '2-oraliq', value: '2-oraliq', text: 'Category 2-CBD dominant medicinal cannabis product (CBD≥60% and less than 98%) - Oral Liquid' },
	{ key: '2-patch', value: '2-patch', text: 'Category 2-CBD dominant medicinal cannabis product (CBD≥60% and less than 98%) - Patch, dermal' },
	{ key: '2-topic', value: '2-topic', text: 'Category 2-CBD dominant medicinal cannabis product (CBD≥60% and less than 98%) - Topical' },

	{ key: '3-cap', value: '3-cap', text: 'Category 3-Balanced medicinal cannabis product (CBD less than 60% and ≥40%) - Capsule' },
	{ key: '3-herb', value: '3-herb', text: 'Category 3-Balanced medicinal cannabis product (CBD less than 60% and ≥40%) - Herb, dried (for vaporisation)' },
	{ key: '3-inh', value: '3-inh', text: 'Category 3-Balanced medicinal cannabis product (CBD less than 60% and ≥40%) - Inhalation' },
	{ key: '3-oraliq', value: '3-oraliq', text: 'Category 3-Balanced medicinal cannabis product (CBD less than 60% and ≥40%) - Oral Liquid' },

	{ key: '4-cap', value: '4-cap', text: 'Category 4-THC dominant medicinal cannabis product (THC 60-98%) - Capsule' },
	{ key: '4-herb', value: '4-herb', text: 'Category 4-THC dominant medicinal cannabis product (THC 60-98%) - Herb, dried (for vaporisation)' },
	{ key: '4-inh', value: '4-inh', text: 'Category 4-THC dominant medicinal cannabis product (THC 60-98%) - Inhalation' },
	{ key: '4-oraliq', value: '4-oraliq', text: 'Category 4-THC dominant medicinal cannabis product (THC 60-98%) - Oral Liquid' },

	{ key: '5-cap', value: '5-cap', text: 'Category 5-THC medicinal cannabis product (THC greater than 98%) - Capsule' },
	{ key: '5-herb', value: '5-herb', text: 'Category 5-THC medicinal cannabis product (THC greater than 98%) - Herb, dried (for vaporisation)' },
	{ key: '5-inh', value: '5-inh', text: 'Category 5-THC medicinal cannabis product (THC greater than 98%) - Inhalation' },
	{ key: '5-oraliq', value: '5-oraliq', text: 'Category 5-THC medicinal cannabis product (THC greater than 98%) - Oral Liquid' },

]

const _MODEL_NAME = 'med_model';

const MAX_ROW_LENGTH = 30;

const _MEDS_FIELDS = [
	{
		name: 'truely active',
		template: ({ value: { truely_active } }) => <Icon color={truely_active ? 'green' : 'red'} name={truely_active ? 'check' : 'close'} />
	},
	{
		name: 'code',
		jpath: 'key'
	},
	{
		name: 'name',
		jpath: 'name',
		type: 'show_more',
		cut_length: MAX_ROW_LENGTH
	},
	{
		name: 'alt name',
		jpath: 'alt_name',
		type: 'show_more',
		cut_length: MAX_ROW_LENGTH
	},
	{
		name: 'active',
		template: ({ value: { active } }) => <Icon color={active ? 'green' : 'red'} name={active ? 'check' : 'close'} />
	},
	{
		name: 'category',
		jpath: 'par_nm',
		type: 'show_more',
		cut_length: MAX_ROW_LENGTH
	},
	{
		name: 'PBSCode',
		jpath: 'PBSCode'
	},
	{
		name: 'EPrescribing',
		template: EPrescribing
	},
	{
		name: 'dosage',
		template: ({ value: { size, qnty } }) => <SimpleShowMore cut_length={MAX_ROW_LENGTH} content={size && qnty ? `${size} X ${qnty}` : size || qnty} />
	},
	{
		name: 'script limit',
		template: ({ value: { days_limit, epresc: { NumberOfRepeatsAuthorised: repeat } = {} } }) =>
			`${days_limit && repeat ? `${days_limit} days, ${repeat} repeat` : days_limit ? `${days_limit} days` : repeat ? `${repeat} repeat` : ''}`
	}
];

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class med_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get MB_FIELDS() { return _MB_FIELDS; }
	static get PATHO_FIELDS() { return _PATHO_FIELDS; }
	static get COSMSCR_FIELDS() { return _COSMSCR_FIELDS; }
	static get MEDIMG_FIELDS() { return _MEDIMG_FIELDS; }
	static get MODEL_NAME() { return _MODEL_NAME; }
	static get ITEM_TYPE() { return _ITEM_TYPE; }
	static get COSM_PRODUCT_USE_TYPE() { return _COSM_PRODUCT_USE_TYPE; }
	static get COSM_TREATMENT_AREA() { return _COSM_TREATMENT_AREA; }
	static get MEDS_FIELDS() { return _MEDS_FIELDS; }
	static get CAT_NAMES() { return cat_nms; }
	static get TGA_DOCUMENT_CATS() { return _TGA_DOCUMENT_CATS; }

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static det_fields_scr() {

		return [
				'name',
				'size',
				'qnty',
				'dose',
				'repeats',
				'days_limit',
				'authority_code',
				'phone_appr',
				'extra',
			]

	}

//	------------------------	------------------------	------------------------

	static det_fields_show_user() {

		return {
			'name': 'name',
			'size': 'size',
			'qnty': 'quantity',
			'dose': 'dosage',
			'repeats': 'repeats',
		}

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async get_pbs_meds_list () {

		if (!app.app_data.ws_data?.pbs_meds_list) {

			if (!app.app_data.ws_data)				app.app_data.ws_data = {};
			app.app_data.ws_data.pbs_meds_list = (await API_service.load_data('get_pbs_meds_list'))?.data ||[];

		}

		return app.app_data.ws_data.pbs_meds_list;

	}
	
	static async items(med) {
		if (!med?.items)
			return null
		return await Promise.all(med.items.map(async k=>{
			let record
			if (typeof k == 'string') {
				record = await this.load_med(  k, {refresh:true} )
				record.key = k
			} else {
				record = k
			}
			return record
		})) 
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------


	static watch_med (mid, ca, p, keep_hist=false) {

		return this.watch_record( mid, async (med, key) => ca( await this.load_med(null, p, med, keep_hist) ) )
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async load_med (mid, p, med, keep_hist=false) {

		med ||= mid && await this.get_record(mid, null, p);

		if (!med)
			return

		const price = !p?.keep_price_var && app.site_status?.prs_mc?.[med.mc_pr_ref];
		if (price) med.mc_pr = price;
	
		if (med?.base_mid) {
			const base_med = await this.load_med(med.base_mid, { keep_price_var: p?.keep_price_var }, null, keep_hist);
			med = {...base_med, ...med, q:{...base_med?.q, ...med?.q}}
		}

		if (p?.load_items && med?.items) {
			await Promise.all(
				med.items.map( async m => {
						const item_med = await this.load_med(m, null, null, keep_hist);
						console.log('adding item', item_med);
						med.q = {...item_med?.q, ...med?.q}
					} ) )
		}

		!keep_hist && delete med.hist;

		return med;
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async get_all_meds_data () {

		if (!app.app_data.ws_data?.all_meds_data) {

			if (!app.app_data.ws_data)				app.app_data.ws_data = {};
			app.app_data.ws_data.all_meds_data = await med_model.get_record( '/' )

			// console.log('get_all_meds_data', app.app_data.ws_data.all_meds_data);

		}

		return app.app_data.ws_data.all_meds_data;

	}

	static async get_all_meds_extra_data() {
		const catNames = this.CAT_NAMES.map((cn) => cn === 'pharm' ? '' : cn);
		const [allMeds, ...CatMeds] = await Promise.all([this.get_all_meds_data(), ...catNames.map((cat_nm) => cat_model.get_cat_list({ cat_nm, all: true, prefix_par_nm: true }))]).catch((err) =>
			console.error('Failed to load categories', err)
		);
		const combineAllCatMeds = CatMeds.map((cat) => cat.cat_show_list).reduce((acc, curr) => Object.assign(acc, curr), {});

		const { medsWithActAndCat, medsMissingInAllMeds } = Object.entries(allMeds).reduce((acc, [k, v]) => {
			if (!combineAllCatMeds[v.m]) {
				acc.medsMissingInAllMeds.push(v);
			}
			
			// Function to see of all parent categories of the med is 'active'. 
			const truely_active = (med) => {
				const active_arr = []
				med.l && med.l.split('/').map(par_id => {
					active_arr.push(combineAllCatMeds[par_id]?.active !== false ? true : false)
				})
				active_arr.push(med?.active === false ? false : true)
				return active_arr.every(v => v === true)
			}

			const active = combineAllCatMeds[k]?.active === undefined || combineAllCatMeds[k]?.active
			acc.medsWithActAndCat.push({
				...v,
				key: k,
				active,
				par_nm: combineAllCatMeds[k]?.par_nm || undefined,
				truely_active: truely_active({...v, active})
			});

			return acc;
		}, { medsWithActAndCat: [], medsMissingInAllMeds: [] });


		const medsMissingInCat = Object.entries(combineAllCatMeds)
			.filter(([k, v]) => v.t === 'm' && !allMeds[k])
			.map(([k, v]) => ({ id: k, name: v.n, category: v.par_nm }));

		return { data: medsWithActAndCat, medsMissingInCat, medsMissingInAllMeds };
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static getPriceOptions () {
		const prs_mc = app?.site_status?.prs_mc || {};
		return [
			{
				key: "",
				value: "",
				text: "Clear",
			},
			...Object.keys(prs_mc)
				.map(key => ({
					key,
					text: `${key} (${prs_mc[key]})`,
					value: key,
				}))
			]
	}
}
