import React from 'react';
import { Container } from 'semantic-ui-react'
import Slider from '../../NUI/Slider';

const IcosmPartners = () => {
    const img_path= "https://storage.googleapis.com/icosm/2/";

    const info = [{img:`${img_path}partners-avaderm.png`}, {img: `${img_path}partners-medical-aesthetic.png`}, {img:`${img_path}partners-xytide.png`}, {img: `${img_path}partners-teoxane.png`}, {img:`${img_path}partners-rejuran.png`}, {img: `${img_path}partners-merz.png`}, {img:`${img_path}partners-hugel.png`}, {img:`${img_path}partners-galderma.png`}, {img:`${img_path}partners-envogue.png`}, {img:`${img_path}partners-dermocosmetica.png`}, {img:`${img_path}partners-cryomed.png`}, {img:`${img_path}partners-allergan.png`}]

    return  <div fluid className="!py-0 md:h-[205px] w-full overflow-hidden">
                <Slider data={info} type="icosm_auto" clsn="rounded" img_cls="shrink-0 max-w-[28vw] md:max-w-[228px]"/>
            </div>
}

export default IcosmPartners;


