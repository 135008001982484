import React from 'react';
import { Container } from 'semantic-ui-react';
import Slider from '../../NUI/Slider';

const img_path = "https://storage.googleapis.com/icosm/";
const why = [
	{
		img: img_path+"2/195D43EC57214.png",
		title: "Erin McIntyre RN",
		sub: "Haus of Skinn Aesthetics",
		desc: <>“I've been with InstantCosmetics since the beginning of my business; they've been really supportive and have assisted me in running my business efficiently. The administrative staff are fantastic, really helpful, and always respond quickly to any emails/questions. All of the Doctors are wonderful. The wait time for scripting is always very short, which makes the days in clinic go much more smoothly...”</>
	},
	{
		img: img_path+"2/195D43EC57213.png",
		title: "Katie Ryan RN",
		sub: "MUSE Aesthetic Clinic",
		desc: <>“I have found InstantCosmetics a very user friendly platform. As a nurse working independently I value the support from both the medical team and all the friendly helpful nursing and admin team. I have no technical difficulties which is key to be able to run my clinic effectively. I value the training opportunities and events Instant offers as a place to network, meet fellow independent clinicians and further my knowledge...”</>
	},
	{
		img: img_path+"2/195D43EC57215.png",
		title: "Charlie Ralph RN",
		sub: "Charlie Ralph Clinic",
		desc: <>“...Instant Cosmetics has consistently exceeded my expectations in every aspect of their business. The customer service team at Instant Cosmetics is nothing short of outstanding... The platform offered by Instant Cosmetics is user-friendly and efficient. Placing orders is straightforward, with intuitive navigation and all the information I need readily available. I choose Instant Cosmetics because they consistently deliver on quality, service, and reliability...”</>
	},
]

const IcosmSlider = () => {

    return   <Container className="relative">
                <h3 className="mx-auto pt-[30px] text-center text-icosm-primary mt-0 mb-[59px]">Why Aesthetic Professionals choose InstantCosmetics</h3>
                <Slider data={why} type="icosm" arrows clsn="rounded" cont="flex md:block px-4 relative gap-8 md:gap-5 w-full md:max-w-[900px] overflow-scroll mx-auto !h-fit" img_cls=""/>
            </Container>
}

export default IcosmSlider;
