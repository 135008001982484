import React, { } from "react";
import { useAsync } from "../../xAppLib/Hooks/useAsync";
import inventory_model from "../../models/inventory_model";
import { Segment, Header } from "semantic-ui-react";
import { ListFilters } from 'xAppLib/DataTable';

export default function ClinicSelector({ oid, setOid, refresh }) {
	const { data = [] } = useAsync(async () => (await inventory_model.orgs()).map(({ oid, name, inventory_access, product_count }) => ({
		key: oid,
		text: name,
		value: oid,
		content: `${name} (${product_count} products)`,
		description: ['optional', 'mandatory'].includes(inventory_access) ? 'Can manage inventory' : 'No inventory access'
	})), [refresh]);

	return <Segment>
		<Segment.Group horizontal>
			<Segment color='green'>
				<Header>Clinic</Header>
				<ListFilters.Filter
					onFilterChange={s => setOid(s.oid_filter)}
					multiple={false}
					name="oid_filter"
					value={oid}
					as='dropdown'
					placeholder="Select a clinic"
					options={[
						{ key: '', value: null, text: '-- Select a clinic --' },
						...(data || [])
					]}
				/>
			</Segment>
		</Segment.Group>
	</Segment>
}